import React from 'react';

function PreferencesSettings() {
  return (
    <div className="settings-section">
      <h2>Preferences</h2>
      <div className="settings-field">
        <label>Language</label>
        <select>
          <option value="english">English</option>
          <option value="spanish">Spanish</option>
        </select>
      </div>
      <div className="settings-field">
        <label>Currency</label>
        <select>
          <option value="usd">USD</option>
          <option value="eur">EUR</option>
        </select>
      </div>
    </div>
  );
}

export default PreferencesSettings;
