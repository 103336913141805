import React, { useState } from 'react';
import './Settings.css';
import GeneralSettings from '../components/SettingsItems/GeneralSettings';
import SecuritySettings from '../components/SettingsItems/SecuritySettings';
import PreferencesSettings from '../components/SettingsItems/PreferenceSettings';
import VerificationSettings from '../components/SettingsItems/VerificationSettings';

function Settings() {
  const [selectedTab, setSelectedTab] = useState('general');

  const renderContent = () => {
    switch (selectedTab) {
      case 'general':
        return <GeneralSettings />;
      case 'security':
        return <SecuritySettings />;
      case 'preferences':
        return <PreferencesSettings />;
      case 'verification':
        return <VerificationSettings />;
      default:
        return <GeneralSettings />;
    }
  };

  return (
    <div className="main">
      <div className="settings-container">
        <div className="settings-sidebar">
          <ul>
            <li
              className={selectedTab === 'general' ? 'active' : ''}
              onClick={() => setSelectedTab('general')}
            >
              <i className="icon-general" /> General
            </li>
            <li
              className={selectedTab === 'security' ? 'active' : ''}
              onClick={() => setSelectedTab('security')}
            >
              <i className="icon-security" /> Security
            </li>
            {/* <li
              className={selectedTab === 'preferences' ? 'active' : ''}
              onClick={() => setSelectedTab('preferences')}
            >
              <i className="icon-preferences" /> Preferences
            </li> */}
            <li
              className={selectedTab === 'verification' ? 'active' : ''}
              onClick={() => setSelectedTab('verification')}
            >
              <i className="icon-verification" /> Verification
            </li>
          </ul>
        </div>
        {renderContent()}
      </div>
    </div>
  );
}

export default Settings;
