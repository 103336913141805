import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const SignInModal = ({ isOpen, closeModal }) => {
    const [identifier, setIdentifier] = useState('');
    const [pwd, setPwd] = useState('');
    const [showPwd, setShowPwd] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const { setUsername, setBalance } = useContext(AuthContext); // Include setBalance here
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://localhost:5000/api/users/login', {
                identifier,
                password: pwd
            }, {
                withCredentials: true
            });

            // Fetch the user profile
            const profileResponse = await axios.get('http://localhost:5000/api/users/profile', { withCredentials: true });
            console.log('Login successful:', profileResponse.data);
            setUsername(profileResponse.data.username);

            // Fetch the user's balance immediately after login
            const balanceResponse = await axios.get('http://localhost:5000/api/users/balance', { withCredentials: true });
            console.log('Balance fetched:', balanceResponse.data);
            setBalance(balanceResponse.data.balance);

            // Redirect to home page
            navigate('/');
        } catch (err) {
            console.error('Login error:', err);
            setErrMsg('Login failed');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="model-overlay">
            <div className="signin-container">
                <div className="signin-content">
                    <h1 className="signup-head">Sign In</h1>
                    <form className="signin-form" onSubmit={handleLogin}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="identifier">Email or Username:</label>
                            <input
                                type="text"
                                id="identifier"
                                name="identifier"
                                value={identifier}
                                onChange={(e) => setIdentifier(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="password">Password:</label>
                            <div className="password-input-container">
                                <input
                                    type={showPwd ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                />
                                <FontAwesomeIcon
                                    icon={showPwd ? faEyeSlash : faEye}
                                    onClick={() => setShowPwd(!showPwd)}
                                    className="eye-icon"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="signin-button" disabled={!pwd || !identifier}>Sign In</button>
                        </div>

                        <div className="dont-have-acct">
                            <p>Don't have an account? <a className="sign-up-in-link" href="/?regModel=register&tab=register">Sign up</a></p>
                        </div>

                        {errMsg && <p>{errMsg}</p>}
                    </form>
                    <button id="deposit-btn" className="proceed-btn" style={{ marginTop: 25 }} onClick={closeModal}>Close</button>

                </div>
            </div>
        </div>
    );
}

export default SignInModal;
