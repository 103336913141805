import React, { useState, useEffect } from 'react';
import './Game.css';
import './Keno.css';
import axios from 'axios';
import KenoImg from '../components/img/keno.png';

function Keno() {
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [gameMode, setGameMode] = useState('Manual');
  const [amount, setAmount] = useState(0);
  const [risk, setRisk] = useState('Medium');
  const [result, setResult] = useState([]); // Result state for yellow tiles
  const [hits, setHits] = useState(0); // Track hits
  const [winnings, setWinnings] = useState(0); // Track winnings
  const [probabilities, setProbabilities] = useState([]);

  const maxSelections = 10;

  useEffect(() => {
    if (selectedNumbers.length > 0) {
      calculateProbabilities();
    }
  }, [selectedNumbers, risk]);

  const handleNumberClick = (number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    } else if (selectedNumbers.length < maxSelections) {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  const handlePlayClick = async () => {
    try {
      const response = await axios.post('http://localhost:8080/api/keno/play/keno', {
        selectedNumbers,
        betAmount: amount,
        risk
      }, { withCredentials: true });

      const { outcome, hits, winnings, newBalance } = response.data;
      setResult(outcome);  // Set the result for yellow tiles
      setHits(hits);
      setWinnings(winnings);
    } catch (error) {
      console.error('Error playing Keno:', error);
    }
  };

  const handleClearTable = () => {
    setSelectedNumbers([]); // Clear selected numbers
    setResult([]); // Clear the result tiles (yellow)
  };

  const probabilityTable = {
    Low: {
      1: [0.70, 1.85],
      2: [0.00, 2.00, 3.80],
      3: [0.00, 1.10, 1.38, 26.00],
      4: [0.00, 0.00, 2.20, 7.90, 90.00],
      5: [0.00, 0.00, 1.50, 4.20, 13.00, 300.00],
      6: [0.00, 0.00, 1.10, 2.00, 6.20, 100.00, 700.00],
      7: [0.00, 0.00, 1.10, 1.60, 3.50, 15.00, 225.00, 700.00],
      8: [0.00, 0.00, 1.10, 1.50, 2.00, 5.50, 39.00, 100.00, 800.00],
      9: [0.00, 0.00, 1.10, 1.30, 1.70, 2.50, 7.50, 50.00, 250.00, 1_000.00],
      10: [0.00, 0.00, 1.10, 1.20, 1.30, 1.80, 3.50, 13.00, 50.00, 250.00, 1_000.00]
    },
    Medium: {
      1: [0.40, 2.75],
      2: [0.00, 1.80, 5.10],
      3: [0.00, 0.00, 2.80, 50.00],
      4: [0.00, 0.00, 1.70, 10.00, 100.00],
      5: [0.00, 0.00, 1.40, 4.00, 14.00, 390.00],
      6: [0.00, 0.00, 0.00, 3.00, 9.00, 180.00, 710.00],
      7: [0.00, 0.00, 0.00, 2.00, 7.00, 30.00, 400.00, 800.00],
      8: [0.00, 0.00, 0.00, 2.00, 4.00, 11.00, 67.00, 400.00, 900.00],
      9: [0.00, 0.00, 0.00, 2.00, 2.50, 5.00, 15.00, 100.00, 500.00, 1_000.00],
      10: [0.00, 0.00, 0.00, 1.60, 2.00, 4.00, 7.00, 26.00, 100.00, 500.00, 1_000.00]
    },
    High: {
      1: [0.00, 3.96],
      2: [0.00, 0.00, 17.10],
      3: [0.00, 0.00, 0.00, 81.50],
      4: [0.00, 0.00, 0.00, 10.00, 259.00],
      5: [0.00, 0.00, 0.00, 4.50, 48.00, 450.00],
      6: [0.00, 0.00, 0.00, 0.00, 11.00, 350.00, 710.00],
      7: [0.00, 0.00, 0.00, 0.00, 7.00, 90.00, 400.00, 800.00],
      8: [0.00, 0.00, 0.00, 0.00, 5.00, 20.00, 270.00, 600.00, 900.00],
      9: [0.00, 0.00, 0.00, 0.00, 4.00, 11.00, 56.00, 500.00, 800.00, 1_000.00],
      10: [0.00, 0.00, 0.00, 0.00, 3.50, 8.00, 13.00, 63.00, 500.00, 800.00, 1_000.00]
    }
  };

  const calculateProbabilities = () => {
    if (!probabilityTable[risk] || !probabilityTable[risk][selectedNumbers.length]) {
      setProbabilities([]);
      return;
    }

    const riskProbabilities = probabilityTable[risk][selectedNumbers.length];
    const newProbabilities = riskProbabilities.map((probability, index) => ({
      hits: index,
      probability: `${probability.toFixed(2)}x`
    }));

    setProbabilities(newProbabilities);
  };

  return (
    <div className="main">
      <div className="game-container">
        <div className="side-panel">
          <h2 className="game-head">Controls</h2>
          <div className="side-inputs">
            <label>Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(parseFloat(e.target.value))}
            />
          </div>
          <div className="side-inputs">
            <label>Risk</label>
            <select value={risk} onChange={(e) => setRisk(e.target.value)}>
              <option>Low</option>
              <option>Medium</option>
              <option>High</option>
            </select>
          </div>
          <div className="side-inputs">
            <div className="auto-clear-btns" id="keno-auto-btns" style={{ width: '116.5%' }}>
              <button className="auto-pick" onClick={() => {
                const uniqueNumbers = new Set();
                while (uniqueNumbers.size < maxSelections) {
                  uniqueNumbers.add(Math.floor(Math.random() * 40) + 1);
                }
                setSelectedNumbers(Array.from(uniqueNumbers));
              }}>
                Auto Pick
              </button>
              <button className="clear-table" onClick={handleClearTable}>
                Clear Picks
              </button>
            </div>
          </div>
          <button id="play-button" onClick={handlePlayClick}>Play</button>
        </div>

        <div className="game-panel">
          <div className="keno-grid-container">
            <div className="keno-grid">
              {Array.from({ length: 40 }, (_, i) => i + 1).map((number) => (
                <div
                  key={number}
                  className={`keno-number ${selectedNumbers.includes(number) ? 'selected' : ''} ${result.includes(number) ? 'result' : ''
                    } ${selectedNumbers.length >= maxSelections && !selectedNumbers.includes(number) ? 'disabled' : ''}`}
                  onClick={() => handleNumberClick(number)}
                >
                  {number}
                </div>
              ))}
            </div>
            <div className="keno-results">
              <h3>Results: {hits} Hits, Winnings: ${winnings}</h3>
            </div>
            {selectedNumbers.length === 0 ? (
              <div className="keno-instructions">
                <p>Select 1 - 10 numbers to play</p>
              </div>
            ) : (
              <div className="keno-probabilities">
                <h3>Probabilities</h3>
                <div className="probabilities-container">
                  {probabilities.map((prob, index) => (
                    <div key={index} className="probability-box">
                      {/* {prob.hits} H:*/} {prob.probability}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* <div className="keno-probabilities">
              <div className="tile-counter-container">
                {probabilities.map((prob, index) => (
                  <div key={index} className="tile-counter">
                     {prob.hits} H: {prob.probability}
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="game-description-card">
        <img src={KenoImg} alt="Keno Game" className="game-description-image" />
        <div className="game-description-content">
          <div className="game-description-header">
            <h2>Play Keno Online</h2>
            <button className="description-button">Description</button>
          </div>
          <p>Roulo Keno is a game mode in which players __.</p>
        </div>
      </div>
    </div>
  );
}

export default Keno;