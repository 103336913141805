import React from 'react';
import { Link } from 'react-router-dom';
import Crash from './img/crash.png';
import './GameElements.css';

function CrashCard(){
    return(
        <Link to="/crash" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={Crash} alt="Crash Game" />
            </div>
        </Link>
    );
}

export default CrashCard