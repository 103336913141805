import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Crash.css';
import CrashImg from '../components/img/crash.png';

const generateCrashPoint = () => {
  return Math.random() * (5 - 1.5) + 1.5;
};

const CrashGame = () => {
  const [multiplier, setMultiplier] = useState(1);
  const [isRunning, setIsRunning] = useState(false);
  const [crashPoint, setCrashPoint] = useState(generateCrashPoint());
  const [hasCrashed, setHasCrashed] = useState(false);
  const [cashOutMultiplier, setCashOutMultiplier] = useState(null);
  const [countdown, setCountdown] = useState(3);
  const [hasCashedOut, setHasCashedOut] = useState(false);
  const [balance, setBalance] = useState(null);
  const [gameId, setGameId] = useState(null);
  const intervalRef = useRef(null);
  const canvasRef = useRef(null);
  const startTimeRef = useRef(null);
  const [yAxisLabels, setYAxisLabels] = useState([1.0, 1.2, 1.4, 1.6, 1.8, 2.0]);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [betAmount, setBetAmount] = useState(0);

  const handleBetAmountChange = (e) => {
    setBetAmount(parseFloat(e.target.value)); // Update state with the input value
  };

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setMultiplier(prevMultiplier => prevMultiplier + 0.01);
        setTimeElapsed(prevTime => prevTime + 0.1);
      }, 100);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isRunning]);

  useEffect(() => {
    if (multiplier >= crashPoint) {
      setHasCrashed(true);
      setIsRunning(false);
    }
  }, [multiplier, crashPoint]);

  useEffect(() => {
    if (hasCrashed) {
      const countdownInterval = setInterval(() => {
        setCountdown(prevCountdown => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            return 3;
          }
        });
      }, 1000);
      return () => clearInterval(countdownInterval);
    }
  }, [hasCrashed]);


  const startGame = async () => {
    setIsRunning(false); // Stop any existing game
    try {
      const response = await axios.post(
        'http://localhost:8080/api/crash/play/crash',
        { betAmount },
        { withCredentials: true }
      );

      const { gameId, crashPoint, newBalance } = response.data;

      setGameId(gameId);
      setCrashPoint(crashPoint);
      setBalance(newBalance);

      // Reset game states and start the game
      setMultiplier(1);
      setHasCrashed(false);
      setCashOutMultiplier(null);
      setIsRunning(true);
      // setCountdown(3);
      setHasCashedOut(false);
      setTimeElapsed(0);
      startTimeRef.current = new Date().getTime();
      clearCanvas();
    } catch (error) {
      console.error('Error starting crash game:', error);
    }
  };


  const cashOut = async () => {
    try {
      const response = await axios.post(
        'http://localhost:8080/api/crash/cashout',
        { gameId, multiplier },
        { withCredentials: true }
      );

      const { winAmount, newBalance } = response.data;

      setBalance(newBalance);
      setCashOutMultiplier(multiplier);
      setHasCashedOut(true);

      alert(`You cashed out and won ${winAmount}`);
    } catch (error) {
      console.error('Error cashing out:', error);
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (canvas) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const maxTime = 20;
      const scaleX = canvas.width / maxTime;

      const segments = 100;
      const points = [];
      for (let i = 0; i <= segments; i++) {
        const t = i / segments;
        const x = t * Math.min(timeElapsed, maxTime) * scaleX;
        const y = canvas.height - ((t * t) * (multiplier - 1) * (canvas.height / 4));
        points.push({ x, y });
      }

      ctx.beginPath();
      ctx.moveTo(points[0].x, canvas.height);
      points.forEach(point => ctx.lineTo(point.x, point.y));
      ctx.lineTo(points[points.length - 1].x, canvas.height);
      ctx.closePath();
      ctx.fillStyle = '#FFA500';
      ctx.fill();

      ctx.beginPath();
      ctx.moveTo(points[0].x, canvas.height);
      points.forEach(point => ctx.lineTo(point.x, point.y));
      ctx.strokeStyle = '#FFFFFF';
      ctx.lineWidth = 8;
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      points.forEach((point, i) => {
        if (i > 0) {
          const prevPoint = points[i - 1];
          const controlX = (point.x + prevPoint.x) / 2;
          const controlY = (point.y + prevPoint.y) / 2;
          ctx.quadraticCurveTo(controlX, controlY, point.x, point.y);
        }
      });
      ctx.strokeStyle = '#FFFFFF';
      ctx.lineWidth = 6;
      ctx.stroke();

      ctx.font = '75px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.fillText(`${multiplier.toFixed(2)}x`, canvas.width / 2, canvas.height / 2);

      ctx.strokeStyle = 'white';
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(0, canvas.height);
      ctx.lineTo(canvas.width, canvas.height);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(0, canvas.height);
      ctx.stroke();

      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.fillStyle = 'white';
      for (let i = 0; i <= maxTime; i += 2) {
        ctx.fillText(`${i}s`, (i / maxTime) * canvas.width, canvas.height - 5);
      }

      ctx.textAlign = 'right';
      ctx.fillStyle = 'white';
      yAxisLabels.forEach((label, index) => {
        const yPos = canvas.height - (index * (canvas.height / (yAxisLabels.length - 1)));
        ctx.fillText(`${label}x`, 25, yPos);
      });

      const endPoint = points[points.length - 1];
      ctx.beginPath();
      ctx.arc(endPoint.x, endPoint.y, 10, 0, 2 * Math.PI, false);
      ctx.fillStyle = 'white';
      ctx.fill();

      if (!isRunning && !hasCrashed) {
        ctx.beginPath();
        ctx.arc(points[0].x, points[0].y, 10, 0, 2 * Math.PI, false);
        ctx.fillStyle = 'white';
        ctx.fill();
      }

      ctx.fillStyle = 'white';
      ctx.textAlign = 'right';
      ctx.fillText(`Total ${timeElapsed.toFixed(1)}s`, canvas.width - 10, canvas.height - 10);
    }
  }, [multiplier, isRunning, hasCrashed, yAxisLabels, timeElapsed]);

  return (
    <div className="main">
      <div className="game-container">
        <div className="side-panel">
          <h2 className="game-head">Controls</h2>
          <div className="side-inputs">
            <label>Amount:</label>
            <input
              type="number"
              value={betAmount}
              onChange={handleBetAmountChange}
            />
          </div>
          <div className="side-inputs">
            <label>Cashout At:</label>
            <input type="number" />
          </div>
          <div className="side-inputs">
            <label>Net Gain on Win:</label>
            <input type="number" />
          </div>
          <button id="play-button" className="crash-btn" onClick={startGame} disabled={isRunning}>Play</button>
          <button id="cashout-button" onClick={cashOut} disabled={!isRunning || hasCashedOut}>Cash Out</button>
        </div>

        <div className="game-panel crash">
          <canvas className="crash-canvas" ref={canvasRef} width={800} height={600}></canvas>
          <div className="bottom-messages">
            {hasCrashed ? (
              <>
                <div className="crash-message">Crashed at {crashPoint.toFixed(2)}x</div>
                {/* <div className="countdown">Resetting in {countdown}...</div> */}
              </>
            ) : (
              <div className="controls"></div>
            )}
            {cashOutMultiplier && (
              <div className="cash-out-message">Cashed out at {cashOutMultiplier.toFixed(2)}x</div>
            )}
          </div>
        </div>
      </div>

      <div className="game-description-card">
        <img src={CrashImg} alt="Crash Game" className="game-description-image" />
        <div className="game-description-content">
          <div className="game-description-header">
            <h2>Play Crash Online</h2>
            <button className="description-button">Description</button>
          </div>
          <p>Roulo Crash is a game mode in which players __.</p>
        </div>
      </div>
    </div>
  );
};

export default CrashGame;