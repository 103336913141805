import React, { useRef, useState, useEffect } from 'react';
import Dropdown from './Dropdown/Dropdown';
import DropdownItem from './DropdownItem/DropdownItem';
import { faCheck, faTimes, faInfoCircle, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
//Start w/ lower or uppercase letter, follow w/ anywhere from 3-23 characters that can be lower or uppercase, digits, or underscore
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
//Requires at least 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 special character; can be anywhere from 8-24 characters total
const EMAIL_REGEX = /^(?=.{6,320}$)(?=.*@)(?=.*\.).*$/;
//Requires 6-23 characters, at least one @ symbol, and at least one period
// const REGISTER_URL = '/register';

const RegisterModal = ({ isOpen, closeModal }) => {

  const emailRef = useRef();
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [showPwd, setShowPwd] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [showMatchPwd, setShowMatchPwd] = useState(false);

  const [dob, setDob] = useState(null);

  const [state, setState] = useState('');

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  // const states = ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"];

  const ageRestrictionDate = new Date().setFullYear(new Date().getFullYear() - 21)

  // useEffect(() => {
  //   userRef.current.focus();
  // }, [])

  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email])

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd, matchPwd])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd, matchPwd])

  const handleRegister = async (e) => {
    e.preventDefault();

    // Validate user input
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    const v3 = EMAIL_REGEX.test(email);
    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/api/users/register', {
        username: user,
        email,
        password: pwd,
        dateOfBirth: dob,
        state
      }, {
        withCredentials: true
      });
      console.log('Registration successful:', response.data);
      navigate('/'); // Redirect to account or home page
    } catch (err) {
      console.error('Registration error:', err);
      setErrMsg('Registration failed');
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {success ? (
        <section>
          <h1>Success!</h1>
          <p>
            <a href="./signin">Sign In</a>
          </p>
        </section>
      ) : (
        <div className="model-overlay">
          <div className="signup-container">
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <div className="signup-content">
              <h1 className="signup-head">Create Account</h1>

              <form className="signup-form" onSubmit={handleRegister}>
                <div className="form-column">
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email:
                      <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                      <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
                    </label>
                    <input
                      type="text"
                      id="email"
                      ref={emailRef}
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                      aria-invalid={validEmail ? "false" : "true"}
                      aria-describedby="emailnote"
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                    />
                    <p id="emailnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      Invalid email address.
                    </p>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="username">
                      Username:
                      <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                      <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
                    </label>
                    <input
                      type="text"
                      id="username"
                      ref={userRef}
                      autoComplete="off"
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      required
                      aria-invalid={validName ? "false" : "true"}
                      aria-describedby="uidnote"
                      onFocus={() => setUserFocus(true)}
                      onBlur={() => setUserFocus(false)}
                    />
                    <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      4 to 24 characters.<br />
                      Must begin with a letter.<br />
                      Letters, numbers, underscores, hyphens allowed.
                    </p>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="date-of-birth">Date of Birth:</label>
                    <DatePicker
                      selected={dob}
                      onChange={date => setDob(date)}
                      className="form-control"
                      maxDate={new Date(ageRestrictionDate)}
                      dateFormat="MM/dd/yyyy"
                      required
                    />
                    <p className="dob-note">*Must be 21 years or older to play.</p>
                  </div>
                </div>

                <div className="form-column">
                  <div className="form-group">
                    <label className="form-label" htmlFor="password">
                      Password:
                      <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                      <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
                    </label>
                    <div className="password-input-container">
                      <input
                        type={showPwd ? "text" : "password"}
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                        aria-invalid={validPwd ? "false" : "true"}
                        aria-describedby="pwdnote"
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => setPwdFocus(false)}
                      />
                      <FontAwesomeIcon
                        icon={showPwd ? faEyeSlash : faEye}
                        onClick={() => setShowPwd(!showPwd)}
                        className="eye-icon"
                      />
                    </div>
                    <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      8 to 24 characters.<br />
                      Must include uppercase and lowercase letters, a number, and a special character.<br />
                      Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                    </p>
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="confirm-pwd">
                      Confirm Password:
                      <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                      <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                    </label>
                    <div className="password-input-container">
                      <input
                        type={showMatchPwd ? "text" : "password"}
                        id="confirm_pwd"
                        onChange={(e) => setMatchPwd(e.target.value)}
                        value={matchPwd}
                        required
                        aria-invalid={validMatch ? "false" : "true"}
                        aria-describedby="confirmnote"
                        onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)}
                      />
                      <FontAwesomeIcon
                        icon={showMatchPwd ? faEyeSlash : faEye}
                        onClick={() => setShowMatchPwd(!showMatchPwd)}
                        className="eye-icon"
                      />
                    </div>
                    <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      Must match the first password input field.
                    </p>
                  </div>

                  <div className="form-group">
                    <div className="signup-btn">
                      <button className="signin-button" disabled={!validName || !validPwd || !validEmail || !validMatch}>Sign Up</button>
                    </div>
                    {/* <label className="form-label" htmlFor="state">State:</label>
                  <select id="state" value={state} onChange={(e) => setState(e.target.value)} className="form-control">
                    <option value="">Select a state</option>
                    {states.map((state) => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select> */}
                  </div>
                  {/* <div className="form-group">
                  <label className="form-label" htmlFor="phone">Phone (Optional):</label>
                  <input type="tel" id="phone" name="phone" placeholder="(555) 555-5555" />
                </div> */}
                </div>
              </form>

              <div className="already-have-acct">
                <p>Already have an account? <a className="sign-up-in-link" href="/?signInModel=signin&tab=signin">Sign in</a></p>
              </div>

              <button id="deposit-btn" className="proceed-btn" style={{ marginTop: 25 }} onClick={closeModal}>Close</button>

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RegisterModal;