import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileRes = await axios.get('http://localhost:8080/api/users/profile', {
          withCredentials: true,
        });
        setUsername(profileRes.data.username);

        const balanceRes = await axios.get('http://localhost:8080/api/users/balance', {
          withCredentials: true,
        });
        setBalance(balanceRes.data.balance);
      } catch (error) {
        console.error('Could not fetch profile or balance');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();

    // Set up polling for balance updates
    const interval = setInterval(async () => {
      try {
        const balanceRes = await axios.get('http://localhost:8080/api/users/balance', {
          withCredentials: true,
        });
        setBalance(balanceRes.data.balance);
      } catch (error) {
        console.error('Error fetching balance update');
      }
    }, 2000); // Poll every 2 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ username, setUsername, balance, setBalance, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
