import React, { useState, useEffect } from 'react';
import GameElements from '../components/GameElements';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import './Slots.css';
import { FaSearch } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faHatWizard } from '@fortawesome/free-solid-svg-icons';
import SlotOneCard from '../components/SlotCards/SlotOneCard';
import SlotTwoCard from '../components/SlotCards/SlotTwoCard';
import SlotThreeCard from '../components/SlotCards/SlotThreeCard';
import SlotFourCard from '../components/SlotCards/SlotFourCard';
import SlotFiveCard from '../components/SlotCards/SlotFiveCard';
import SlotSixCard from '../components/SlotCards/SlotSixCard';
import SlotSevenCard from '../components/SlotCards/SlotSevenCard';
import SlotEightCard from '../components/SlotCards/SlotEightCard';
import SlotNineCard from '../components/SlotCards/SlotNineCard';
import SlotTenCard from '../components/SlotCards/SlotTenCard';

function Slots() {
    return (
        <div className="main">
            <div className="slots-page">
                <div className="swiper-container-slots">
                    <div className="header-slots">
                        <h1>Slots</h1>
                    </div>
                    {/* Search Bar Below */}
                    {/* <div className="header-slots">
                        <div className="search-container">
                            <FaSearch className="icon" />
                            <input type="text" className="search-bar" placeholder="Search..." />
                        </div>
                    </div> */}
                    <div className="sub-main">
                        <div className="sub-main-solos">
                            <SlotOneCard />
                            <SlotTwoCard />
                            <SlotThreeCard />
                            <SlotFourCard />
                            <SlotFiveCard />
                            <SlotSixCard />
                            <SlotSevenCard />
                            <SlotEightCard />
                            <SlotNineCard />
                            <SlotTenCard />
                        </div>

                        {/* <Swiper
                        modules={[Navigation]}
                        spaceBetween={newSpaceBetweenBottom}
                        slidesPerView={SlidesPerViewBottom}
                        navigation
                    >
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                    </Swiper> */}
                    </div>
                </div>
            </div>

            <div className="livefeed">
                <h1 className="game-slider-header" style={{ marginBottom: "20px" }}>
                    <FontAwesomeIcon icon={faHatWizard} className="game-icons slider-head-icon" />
                    Recent Games
                </h1>
                <div class="livefeed-row top-livefeed-row">
                    <div class="livefeed-row-one">Game</div>
                    <div class="livefeed-row-one">User</div>
                    <div class="livefeed-row-one">Bet Amount</div>
                    <div class="livefeed-row-one livefeed-multiplier">Multiplier</div>
                    <div class="livefeed-row-one livefeed-payout">Payout</div>
                </div>
                <div class="livefeed-row">
                    <div class="livefeed-game-name">Roulette</div>
                    <div class="livefeed-username">Hidden</div>
                    <div class="livefeed-bet-amount">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.18
                    </div>
                    <div class="livefeed-multiplier">0.00x</div>
                    <div class="livefeed-payout">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.00
                    </div>
                </div>
                <div class="livefeed-row">
                    <div class="livefeed-game-name">Baccarat</div>
                    <div class="livefeed-username">Hidden</div>
                    <div class="livefeed-bet-amount">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.18
                    </div>
                    <div class="livefeed-multiplier">0.00x</div>
                    <div class="livefeed-payout">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.00
                    </div>
                </div>
                <div class="livefeed-row">
                    <div class="livefeed-game-name">Blackjack</div>
                    <div class="livefeed-username">Hidden</div>
                    <div class="livefeed-bet-amount">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.18
                    </div>
                    <div class="livefeed-multiplier">0.00x</div>
                    <div class="livefeed-payout">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.00
                    </div>
                </div>
                <div class="livefeed-row">
                    <div class="livefeed-game-name">Crash</div>
                    <div class="livefeed-username">Hidden</div>
                    <div class="livefeed-bet-amount">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.18
                    </div>
                    <div class="livefeed-multiplier">0.00x</div>
                    <div class="livefeed-payout">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.00
                    </div>
                </div>
                <div class="livefeed-row">
                    <div class="livefeed-game-name">50/50 Dice</div>
                    <div class="livefeed-username">Hidden</div>
                    <div class="livefeed-bet-amount">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.18
                    </div>
                    <div class="livefeed-multiplier">0.00x</div>
                    <div class="livefeed-payout">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.00
                    </div>
                </div>
                <div class="livefeed-row">
                    <div class="livefeed-game-name">Mines</div>
                    <div class="livefeed-username">Hidden</div>
                    <div class="livefeed-bet-amount">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.18
                    </div>
                    <div class="livefeed-multiplier">0.00x</div>
                    <div class="livefeed-payout">
                        <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
                        0.00
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Slots;