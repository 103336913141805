import React, { useState, useEffect } from 'react';
import './Mines.css'; // Ensure this file is properly linked for the styles
import axios from 'axios'; // For API requests
import MinesImg from '../components/img/mines.png';

function Mines() {
    const [grid, setGrid] = useState(Array(25).fill(null)); // Initialize grid with 25 null values
    const [flipped, setFlipped] = useState(Array(25).fill(false)); // State for flipped cards
    const [mines, setMines] = useState(1); // Number of mines
    const [minePositions, setMinePositions] = useState([]); // Positions of mines
    const [exploded, setExploded] = useState(false); // State for explosion
    const [gameId, setGameId] = useState(null); // Track the game session
    const [winnings, setWinnings] = useState(0); // Track winnings
    const [betAmount, setBetAmount] = useState(0); // Bet amount
    const [outcome, setOutcome] = useState('pending'); // Outcome of the game
    const [explodedIndex, setExplodedIndex] = useState(null); // Track the specific index that exploded


    // Initialize the grid and reset states when the component mounts
    useEffect(() => {
        if (gameId) {
            initializeGrid();
        }
    }, [mines]);

    const initializeGrid = () => {
        const newGrid = Array.from({ length: 25 }, (_, i) => i); // Create a grid with 25 cells
        setGrid(newGrid);
        setFlipped(Array(25).fill(false)); // Reset flipped state
        setExploded(false); // Reset explosion state
        setWinnings(0); // Reset winnings
        setOutcome('pending'); // Reset outcome
    };

    // Function to start the game by calling the backend
    const startGame = async () => {
        try {
            const response = await axios.post('http://localhost:8080/api/mines/start/mines', {
                betAmount,
                minesCount: mines,
            }, { withCredentials: true });

            const { gameId: id, minePositions: positions } = response.data;

            setGameId(id);
            setMinePositions(positions); // Save mine positions from the backend
            setFlipped(Array(25).fill(false)); // Reset flipped grid
            setExploded(false);
            setWinnings(0);
            setOutcome('pending');
        } catch (error) {
            console.error('Error starting Mines game:', error);
        }
    };

    // Function to flip a tile by calling the backend
    const flipTile = async (index) => {
        // Prevent flipping if the tile is already flipped or the game is over
        if (flipped[index] || outcome !== 'pending') return;

        try {
            const response = await axios.post('http://localhost:8080/api/mines/flip', {
                gameId,
                cellIndex: index,
            }, { withCredentials: true });

            const { flippedCells, winnings: newWinnings, outcome: newOutcome } = response.data;

            // Update the frontend state based on the backend response
            const updatedFlipped = [...flipped];
            updatedFlipped[index] = true;  // Only flip the clicked tile

            setFlipped(updatedFlipped);  // Set flipped tiles with the updated array
            setWinnings(newWinnings.toFixed(2));
            setOutcome(newOutcome);

            if (newOutcome === 'lose') {
                setExplodedIndex(index);  // Set the exploded index to the clicked mine tile
            }
        } catch (error) {
            console.error('Error flipping tile:', error);
        }
    };


    // Function to cash out by calling the backend
    const cashOut = async () => {
        try {
            const response = await axios.post('http://localhost:8080/api/mines/cashout', {
                gameId,
            }, { withCredentials: true });

            const { winnings: finalWinnings } = response.data;
            setWinnings(finalWinnings);
            setOutcome('cashed_out');
        } catch (error) {
            console.error('Error cashing out:', error);
        }
    };

    const handleMineChange = (e) => {
        setMines(parseInt(e.target.value, 10)); // Set number of mines from dropdown
    };

    return (
        <div className="main" id="main">
            <div className="game-container" style={{ marginTop: '100px' }}>
                <div className="side-panel">
                    <div style={{ marginBottom: '20px' }}>
                        <h2 className="game-head">Controls</h2>
                        <div>
                            <label>Amount:</label>
                            <input type="number" value={betAmount} onChange={(e) => setBetAmount(parseFloat(e.target.value))} />
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <label>Mines:</label>
                            <select value={mines} onChange={handleMineChange}>
                                {[...Array(24).keys()].map((num) => (
                                    <option key={num} value={num + 1}>{num + 1}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <button id="play-button" className="btn-play" onClick={startGame}>Play</button>
                    <button id="cashout-button" className="btn-cashout" onClick={cashOut} disabled={outcome !== 'pending'}>Cash Out</button>
                    <div className="winnings">Winnings: ${winnings}</div>
                </div>

                <div className="game-panel mines">
                    <div className="button-grid">
                        {grid.map((_, index) => (
                            <div
                                className={`grid-button ${flipped[index] ? 'flipped' : ''} ${index === explodedIndex ? 'exploded' : ''}`}
                                key={index}
                                onClick={() => flipTile(index)}
                            >
                                <div className="front"></div>
                                <div className="back">
                                    {flipped[index] && minePositions.includes(index) ? 'Mine' : flipped[index] ? 'Diamond' : ''}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="game-description-card">
                <img src={MinesImg} alt="Mines Game" className="game-description-image" />
                <div className="game-description-content">
                    <div className="game-description-header">
                        <h2>Play Mines Online</h2>
                        <button className="description-button">Description</button>
                    </div>
                    <p>Roulo Mines is a game mode in which players __.</p>
                </div>
            </div>
        </div>
    );
}

export default Mines;