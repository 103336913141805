import "./App.css";
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Simulation from './pages/Simulation';
import Plinko from './pages/Plinko';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home';
import SignIn from './components/SignIn';
import Register from './components/Register';
import Tables from './pages/Tables';
import Slots from './pages/Slots';
import Originals from './pages/Originals';
import AllGames from './pages/AllGames';
import Dice from './pages/Dice';
import Mines from './pages/Mines';
import Crash from './pages/Crash';
import Blackjack from './pages/Blackjack';
import Keno from './pages/Keno';
// import Wheel from './pages/Wheel';
// import PVPCoin from './pages/PVPCoin';
import SidebarNav from "./components/SidebarNav";
// import Tower from './pages/Tower';
import Baccarat from './pages/Baccarat';
import { AuthProvider } from './context/AuthContext';
// import Roulette from './pages/Roulette';
import Settings from './pages/Settings';

function App() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <AuthProvider>
        <Router>
          {/* <div className={`app-container ${isSidebarOpen ? 'sidenav-open' : ''}`}> */}
            <div>
            <SidebarNav isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            <div className="main-content">
              <Navbar />
              <Routes>
                <Route path="/" exact={true} element={<Home />} />
                <Route path="/plinko" exact={true} element={<Plinko />} />
                <Route path="/simulation" exact={true} element={<Simulation />} />
                <Route path="keno" exact={true} element={<Keno />} />
                <Route path="/signin" exact={true} element={<SignIn />} />
                <Route path="/register" exact={true} element={<Register />} />
                <Route path="/originals" exact={true} element={<Originals />} />
                <Route path="/tables" exact={true} element={<Tables />} />
                <Route path="/slots" exact={true} element={<Slots />} />
                <Route path="/all-games" exact={true} element={<AllGames />} />
                <Route path="/dice" exact={true} element={<Dice />} />
                <Route path="/mines" exact={true} element={<Mines />} />
                <Route path="/crash" exact={true} element={<Crash />} />
                <Route path="/blackjack" exact={true} element={<Blackjack />} />
                {/* <Route path="/wheel" exact={true} element={<Wheel />} /> */}
                {/* <Route path="/pvpcoin" exact={true} element={<PVPCoin />} /> */}
                {/* <Route path="/tower" exact={true} element={<Tower />} /> */}
                <Route path="/baccarat" exact={true} element={<Baccarat />} />
                {/* <Route path="/roulette" exact={true} element={<Roulette />} /> */}
                <Route path="/settings" exact={true} element={<Settings />} />
              </Routes>
              <Footer />
            </div>
          </div>
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;