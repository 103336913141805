import React, { useRef, useEffect, useState } from 'react';
import './Game.css';
import './Plinko.css';
import axios from 'axios';
import PlinkoImg from '../components/img/plinko.png';

function Game() {
  const canvasRef = useRef(null);
  const [rows, setRows] = useState(15);
  const [risk, setRisk] = useState('Low');
  const [hitMultiplier, setHitMultiplier] = useState(null);
  const [betAmount, setBetAmount] = useState(0); // Store the bet amount
  const [gameId, setGameId] = useState(null); // To track game session
  const [multiplierSent, setMultiplierSent] = useState(false);
  const balls = useRef([]); // Move balls array to useRef to persist between renders
  
  const getMultipliers = () => {
    let baseMultipliers = [];
    // Cases for Low risk
    if (risk === 'Low' && rows === 8) {
      baseMultipliers = [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6];
    }
    else if (risk === 'Low' && rows === 9) {
      baseMultipliers = [5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6];
    }
    else if (risk === 'Low' && rows === 10) {
      baseMultipliers = [8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9];
    }
    else if (risk === 'Low' && rows === 11) {
      baseMultipliers = [8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4];
    }
    else if (risk === 'Low' && rows === 12) {
      baseMultipliers = [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10];
    }
    else if (risk === 'Low' && rows === 13) {
      baseMultipliers = [8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1];
    }
    else if (risk === 'Low' && rows === 14) {
      baseMultipliers = [7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1];
    }
    else if (risk === 'Low' && rows === 15) {
      baseMultipliers = [15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 18];
    }
    else if (risk === 'Low' && rows === 16) {
      baseMultipliers = [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16];
    }

    // Cases for Medium risk
    if (risk === 'Medium' && rows === 8) {
      baseMultipliers = [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13];
    }
    else if (risk === 'Medium' && rows === 9) {
      baseMultipliers = [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18];
    }
    else if (risk === 'Medium' && rows === 10) {
      baseMultipliers = [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22];
    }
    else if (risk === 'Medium' && rows === 11) {
      baseMultipliers = [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24];
    }
    else if (risk === 'Medium' && rows === 12) {
      baseMultipliers = [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33];
    }
    else if (risk === 'Medium' && rows === 13) {
      baseMultipliers = [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43];
    }
    else if (risk === 'Medium' && rows === 14) {
      baseMultipliers = [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58];
    }
    else if (risk === 'Medium' && rows === 15) {
      baseMultipliers = [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88];
    }
    else if (risk === 'Medium' && rows === 16) {
      baseMultipliers = [110, 41, 10, 5, 3, 1.5, 1, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110];
    }

    // Cases for High risk
    if (risk === 'High' && rows === 8) {
      baseMultipliers = [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29];
    }
    else if (risk === 'High' && rows === 9) {
      baseMultipliers = [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43];
    }
    else if (risk === 'High' && rows === 10) {
      baseMultipliers = [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76];
    }
    else if (risk === 'High' && rows === 11) {
      baseMultipliers = [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120];
    }
    else if (risk === 'High' && rows === 12) {
      baseMultipliers = [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170];
    }
    else if (risk === 'High' && rows === 13) {
      baseMultipliers = [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260];
    }
    else if (risk === 'High' && rows === 14) {
      baseMultipliers = [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420];
    }
    else if (risk === 'High' && rows === 15) {
      baseMultipliers = [620, 83, 27, 8, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620];
    }
    else if (risk === 'High' && rows === 16) {
      baseMultipliers = [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000];
    }

    // Adjust the number of multiplier boxes based on the number of rows
    const adjustedMultipliers = [];
    for (let i = 0; i <= rows; i++) {
      adjustedMultipliers.push(baseMultipliers[i % baseMultipliers.length]);
    }
    return adjustedMultipliers;
  };

  const getBoxColor = (index, totalBoxes) => {
    if (totalBoxes === 9) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF302F';
      if (index === 2 || index === totalBoxes - 3) return '#FF6020';
      if (index === 3 || index === totalBoxes - 4) return '#FF9010';
      return '#FFC000';
    }
    else if (totalBoxes === 10) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF2B31';
      if (index === 2 || index === totalBoxes - 3) return '#FF5523';
      if (index === 3 || index === totalBoxes - 4) return '#FF8015';
      if (index === 4 || index === totalBoxes - 5) return '#FFAB07';
    }
    else if (totalBoxes === 11) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF2632';
      if (index === 2 || index === totalBoxes - 3) return '#FF4D26';
      if (index === 3 || index === totalBoxes - 4) return '#FF7319';
      if (index === 4 || index === totalBoxes - 5) return '#FF9A0D';
      return '#FFC000';
    }
    else if (totalBoxes === 12) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF2334';
      if (index === 2 || index === totalBoxes - 3) return '#FF4628';
      if (index === 3 || index === totalBoxes - 4) return '#FF691D';
      if (index === 4 || index === totalBoxes - 5) return '#FF8C11';
      if (index === 5 || index === totalBoxes - 6) return '#FFAF06';
    }
    else if (totalBoxes === 13) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF2035';
      if (index === 2 || index === totalBoxes - 3) return '#FF402A';
      if (index === 3 || index === totalBoxes - 4) return '#FF6020';
      if (index === 4 || index === totalBoxes - 5) return '#FF8015';
      if (index === 5 || index === totalBoxes - 6) return '#FFA00B';
      return '#FFC000';
    }
    else if (totalBoxes === 14) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF1E35';
      if (index === 2 || index === totalBoxes - 3) return '#FF3B2C';
      if (index === 3 || index === totalBoxes - 4) return '#FF5922';
      if (index === 4 || index === totalBoxes - 5) return '#FF7618';
      if (index === 5 || index === totalBoxes - 6) return '#FF940F';
      if (index === 6 || index === totalBoxes - 7) return '#FFB105';
    }
    else if (totalBoxes === 15) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF1B36';
      if (index === 2 || index === totalBoxes - 3) return '#FF372D';
      if (index === 3 || index === totalBoxes - 4) return '#FF5224';
      if (index === 4 || index === totalBoxes - 5) return '#FF6E1B';
      if (index === 5 || index === totalBoxes - 6) return '#FF8912';
      if (index === 6 || index === totalBoxes - 7) return '#FFA509';
      return '#FFC000';
    }
    else if (totalBoxes === 16) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF1A37';
      if (index === 2 || index === totalBoxes - 3) return '#FF332E';
      if (index === 3 || index === totalBoxes - 4) return '#FF4D26';
      if (index === 4 || index === totalBoxes - 5) return '#FF661D';
      if (index === 5 || index === totalBoxes - 6) return '#FF8015';
      if (index === 6 || index === totalBoxes - 7) return '#FF9A0D';
      if (index === 7 || index === totalBoxes - 8) return '#FFB304';
    }
    else if (totalBoxes === 17) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF1837';
      if (index === 2 || index === totalBoxes - 3) return '#FF302F';
      if (index === 3 || index === totalBoxes - 4) return '#FF4827';
      if (index === 4 || index === totalBoxes - 5) return '#FF6020';
      if (index === 5 || index === totalBoxes - 6) return '#FF7818';
      if (index === 6 || index === totalBoxes - 7) return '#FF9010';
      if (index === 7 || index === totalBoxes - 8) return '#FFA808';
      return '#FFC000';
    }
  };

  const multipliers = getMultipliers();

  // Start game by calling backend
  const startGame = async () => {
    try {
      const response = await axios.post('http://localhost:8080/api/plinko/start', {
        betAmount,
        rows,
        risk,
      }, { withCredentials: true });

      console.log('Game started:', response.data);
      setGameId(response.data.gameId);  // Make sure gameId is properly set here
      setMultiplierSent(false);
    } catch (error) {
      console.error('Error starting Plinko game:', error);
    }
  };
  
  // UseEffect to initialize the canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const playButton = document.getElementById('play-button');
    const DECIMAL_MULTIPLIER = 10000;
    const WIDTH = 800;
    const HEIGHT = 600;
    const ballRadius = 7;
    const obstacleRadius = 4;
    const gravity = 800;
    const horizontalFric = 0.4;
    const verticalFric = 0.8;
    let obstacles = [];

    function increase(n) {
      return n * DECIMAL_MULTIPLIER;
    }

    const handlePlayButtonClick = () => {
      addBall();  // Your function to add a new ball
    };

    function unincrease(n) {
      return Math.floor(n / DECIMAL_MULTIPLIER);
    }

    // Initialize obstacles
    obstacles = [];
    const spacing = WIDTH / (rows + 1);

    for (let row = 2; row < rows; row++) {
      const numObstacles = row + 1;
      const y = row * (HEIGHT / rows);
      for (let col = 0; col < numObstacles; col++) {
        const x = WIDTH / 2 - spacing * (row / 2 - col);
        obstacles.push({ x: increase(x), y: increase(y), radius: obstacleRadius });
      }
    }

    class Ball {
      constructor(x, y, radius, color) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.color = color;
        this.vx = 0;
        this.vy = 0;
        this.landed = false; // Track if the ball has landed
      }

      draw() {
        ctx.beginPath();
        ctx.arc(unincrease(this.x), unincrease(this.y), this.radius, 0, Math.PI * 2);
        ctx.fillStyle = this.color;
        ctx.fill();
        ctx.closePath();
      }

      update() {
        if (!this.landed) {
          this.vy += gravity;
          this.x += this.vx;
          this.y += this.vy;

          // Collision detection logic
          obstacles.forEach(obstacle => {
            const dist = Math.hypot(this.x - obstacle.x, this.y - obstacle.y);
            if (dist < increase(this.radius + obstacle.radius)) {
              const angle = Math.atan2(this.y - obstacle.y, this.x - obstacle.x);
              const speed = Math.sqrt(this.vx * this.vx + this.vy * this.vy);
              this.vx = Math.cos(angle) * speed * horizontalFric;
              this.vy = Math.sin(angle) * speed * verticalFric;


              const overlap = this.radius + obstacle.radius - unincrease(dist);
              this.x += increase(Math.cos(angle) * overlap);
              this.y += increase(Math.sin(angle) * overlap);

              // Highlight pegs
              obstacle.highlighted = true;
              setTimeout(() => (obstacle.highlighted = false), 300);
            }
          });

          // Detect when the ball has landed
          if (unincrease(this.y) + ballRadius > HEIGHT + 50 && !this.landed) {
            this.landed = true;
            checkMultiplierHit(unincrease(this.x)); // Call multiplier hit only once when the ball lands
          }
        }
      }
    }

    function checkMultiplierHit(ballX) {
      const multiplierDivs = document.querySelectorAll('.odds-box');
      const canvasRect = canvasRef.current.getBoundingClientRect();
      let hit = null;
    
      multiplierDivs.forEach((div, index) => {
        const rect = div.getBoundingClientRect();
        const divLeft = rect.left - canvasRect.left;
        const divRight = rect.right - canvasRect.left;

        // Check if the ball's X-coordinate is within this div's boundaries
        if (ballX >= divLeft && ballX <= divRight) {
          hit = multipliers[index]; // Use the correct multiplier from the multipliers array
        }
      });

      if (hit !== null && !multiplierSent) {
        updateMultiplier(hit); // Call the function to send the multiplier to the backend
        setHitMultiplier(hit); // Store hit multiplier
        setMultiplierSent(true); // Ensure the request is only sent once
      }
    }
  
    // Add ball to the game
    const addBall = () => {
      // Add new ball without clearing existing balls
      const startX = increase(WIDTH / 2 - 36 + Math.random() * 72); // Random starting position
      const newBall = new Ball(startX, increase(50), ballRadius, 'red');
      balls.current.push(newBall); // Add the new ball to the array
    };

    // Function to draw obstacles
    const drawObstacles = () => {
      ctx.fillStyle = 'white';
      obstacles.forEach(obstacle => {
        ctx.beginPath();
        ctx.arc(unincrease(obstacle.x), unincrease(obstacle.y), obstacle.radius, 0, Math.PI * 2);
        ctx.fillStyle = obstacle.highlighted ? 'red' : 'white';
        ctx.fill();
        ctx.closePath();
      });
    };

    if (playButton) {
      playButton.addEventListener('click', handlePlayButtonClick);
    }

    const update = () => {
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      drawObstacles();
      balls.current.forEach(ball => {
        ball.draw();
        ball.update();
      });
      requestAnimationFrame(update);
    };

    update();
    return () => {
      if (playButton) {
        playButton.removeEventListener('click', handlePlayButtonClick);
      }
    };
  }, [rows]);

  // Send hit multiplier to backend
const updateMultiplier = async (multiplier) => {
  try {
      const response = await axios.post('http://localhost:8080/api/plinko/multiplier', {
          multiplier,
      }, { withCredentials: true });

      console.log('Multiplier updated:', response.data);
  } catch (error) {
      console.error('Error updating multiplier:', error);
  }
};

  return (
    <div className="main">
      <div className="game-container">
        <div className="side-panel">
          <h2 className="game-head">Controls</h2>
          <div className="side-inputs">
            <label>Amount:</label>
            <input
              type="number"
              value={betAmount}
              onChange={(e) => setBetAmount(parseFloat(e.target.value))}
            />
          </div>
          <div className="side-inputs">
            <label>Risk:</label>
            <select value={risk} onChange={(e) => setRisk(e.target.value)}>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </div>
          <div className="side-inputs">
            <label>Rows:</label>
            <select value={rows} onChange={(e) => setRows(parseInt(e.target.value))}>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
              <option value={15}>15</option>
              <option value={16}>16</option>
            </select>
          </div>
          <button id="play-button" onClick={startGame}>Play</button>
        </div>

        <div className="game-panel plinko">
          <canvas className="plinko-canvas" ref={canvasRef} width="800" height="600"></canvas>
          {hitMultiplier !== null && <div className="multiplier-hit">Hit: {hitMultiplier}x</div>}

          <div className="odds-container plinko-odds">
            {multipliers.map((multiplier, index) => (
              <div
                key={index}
                className="odds-box plinko-box"
                style={{
                  backgroundColor: getBoxColor(index, multipliers.length),
                  transition: 'background-color 0.3s ease',
                }}
              >
                {multiplier}x
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="game-description-card">
        <img src={PlinkoImg} alt="Plinko Game" className="game-description-image" />
        <div className="game-description-content">
          <div className="game-description-header">
            <h2>Play Plinko Online</h2>
            <button className="description-button">Description</button>
          </div>
          <p>Roulo Plinko is a game mode in which players __.</p>
        </div>
      </div>

    </div>
  );
}

export default Game;