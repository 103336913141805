import React, { useState } from 'react';
import './DepositModal.css';
import Bitcoin from './cryptoImgs/bitcoin.png';
import Ethereum from './cryptoImgs/ethereum.jpg';
import Litecoin from './cryptoImgs/litecoin.jpg';
import Tether from './cryptoImgs/tether.png';
import USDCoin from './cryptoImgs/usdc.png';
import XRP from './cryptoImgs/xrp.png';
import Solana from './cryptoImgs/solana.png';
import Tron from './cryptoImgs/tronix.png';
import Dogecoin from './cryptoImgs/dogecoin.png';

const DepositModal = ({ isOpen, closeModal }) => {
    const amount = 3;
    const [currency, setCurrency] = useState('btc'); // Default to Bitcoin
    const [payAddress, setPayAddress] = useState(''); // State to store the pay address
    const [view, setView] = useState('deposit'); // Toggle between Deposit and Withdrawal views

    const handleDeposit = async () => {
        try {
            console.log('Deposit amount:', amount);  // Add this to verify the amount
            const response = await fetch('http://localhost:8080/api/crypto/deposit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ amount, currency }),
                credentials: 'include' // Send cookies with the request
            });
            const data = await response.json();
            setPayAddress(data.pay_address); // Save the pay_address from the response
            console.log('Payment Data:', data.pay_address); // Log the response for debugging
        } catch (error) {
            console.error('Error creating deposit:', error);
        }
    };

    const cryptoOptions = [
        { value: 'btc', label: 'Bitcoin (BTC)', icon: Bitcoin },
        { value: 'eth', label: 'Ethereum (ETH)', icon: Ethereum },
        { value: 'ltc', label: 'Litecoin (LTC)', icon: Litecoin },
        { value: 'usdt', label: 'Tether (USDT)', icon: Tether },
        { value: 'usdc', label: 'USDCoin (USDC)', icon: USDCoin },
        { value: 'xrp', label: 'XRP (XRP)', icon: XRP },
        { value: 'sol', label: 'Solana (SOL)', icon: Solana },
        { value: 'trx', label: 'Tronix (TRX)', icon: Tron },
        { value: 'doge', label: 'Dogecoin (DOGE)', icon: Dogecoin }
    ];

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="model-head">
                    <button
                        className={`tab-button ${view === 'deposit' ? 'active-tab' : ''}`}
                        onClick={() => setView('deposit')}
                    >
                        Deposit
                    </button>
                    <button
                        className={`tab-button ${view === 'withdraw' ? 'active-tab' : ''}`}
                        onClick={() => setView('withdraw')}
                    >
                        Withdraw
                    </button>
                </div>

                {view === 'deposit' ? (
                    <div>
                        <div className="crypto-selection">
                            <button id="deposit-btn" className={currency === 'btc' ? 'active' : ''} onClick={() => setCurrency('btc')}>
                                <img src={Bitcoin} className="crypto-img" alt="Bitcoin" />
                                Bitcoin (BTC)
                            </button>
                            <button id="deposit-btn" className={currency === 'eth' ? 'active' : ''} onClick={() => setCurrency('eth')}>
                                <img src={Ethereum} className="crypto-img" alt="Ethereum" />
                                Ethereum (ETH)
                            </button>
                            <button id="deposit-btn" className={currency === 'ltc' ? 'active' : ''} onClick={() => setCurrency('ltc')}>
                                <img src={Litecoin} className="crypto-img" alt="Litecoin" />
                                Litecoin (LTC)
                            </button>
                        </div>
                        <div className="crypto-selection">
                            <button id="deposit-btn" className={currency === 'usdt' ? 'active' : ''} onClick={() => setCurrency('usdt')}>
                                <img src={Tether} className="crypto-img" alt="Tether" />
                                Tether (USDT)
                            </button>
                            <button id="deposit-btn" className={currency === 'usdc' ? 'active' : ''} onClick={() => setCurrency('usdc')}>
                                <img src={USDCoin} className="crypto-img" alt="USDCoin" />
                                USDCoin (USDC)
                            </button>
                            <button id="deposit-btn" className={currency === 'xrp' ? 'active' : ''} onClick={() => setCurrency('xrp')}>
                                <img src={XRP} className="crypto-img" alt="XRP" /><br></br>
                                XRP (XRP)
                            </button>
                        </div>
                        <div className="crypto-selection">
                            <button id="deposit-btn" className={currency === 'sol' ? 'active' : ''} onClick={() => setCurrency('sol')}>
                                <img src={Solana} className="crypto-img" alt="Solana" />
                                Solana (SOL)
                            </button>
                            <button id="deposit-btn" className={currency === 'trx' ? 'active' : ''} onClick={() => setCurrency('trx')}>
                                <img src={Tron} className="crypto-img" alt="Tron" />
                                Tronix (TRX)
                            </button>
                            <button id="deposit-btn" className={currency === 'doge' ? 'active' : ''} onClick={() => setCurrency('doge')}>
                                <img src={Dogecoin} className="crypto-img" alt="Dogecoin" />
                                Dogecoin (DOGE)
                            </button>
                        </div>
                        <button id="deposit-btn" className="proceed-btn" onClick={handleDeposit}>Proceed</button>
                        {payAddress && (
                            <div>
                                <h2 id='fun-deposit'>Payment Address</h2>
                                <p id='payment-address'>{payAddress}</p>
                            </div>
                        )}
                        <button id="deposit-btn" className="proceed-btn" onClick={closeModal}>Close</button>
                    </div>
                ) : (
                    <div className="withdrawal-section">
                        <div className="withdrawal-field">
                            <label>Crypto Currency:</label>
                            <select value={currency} onChange={(e) => setCurrency(e.target.value)} className="crypto-dropdown">
                                {cryptoOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="withdrawal-field">
                            <label>Withdraw To:</label>
                            <input type="text" placeholder="Your BTC address" />
                        </div>
                        <div className="withdrawal-field">
                            <label>Amount to Withdraw:</label>
                            <input type="text" placeholder="$0.00" />
                        </div>
                        <div className="transaction-fee">
                            <p>$0.44 Estimated Transaction Fee</p>
                        </div>
                        <button className="withdraw-btn">Withdraw</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DepositModal;