import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameFive from '../img/SlotCards/pragmatic_vs20fruitswroo.png';
import '../GameElements.css';

function SlotGameFiveCard(){
    return(
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameFive} alt="Slot Game Five" />
            </div>
        </Link>
    );
}

export default SlotGameFiveCard