import React, { useState } from 'react';
import axios from 'axios';
import './Dice.css';
import DiceImg from '../components/img/dice.png';

const DiceGame = () => {
  const [amount, setAmount] = useState(0);
  const [netGain, setNetGain] = useState(0);
  const [winChance, setWinChance] = useState(50);
  const [rollOver, setRollOver] = useState(50.50);
  const [winnings, setWinnings] = useState(2.0);
  const [sliderValue, setSliderValue] = useState(50);
  const [balance, setBalance] = useState(null);  // Store user's balance
  const [loading, setLoading] = useState(false);  // To handle loading state

  const handleSliderChange = (e) => {
    const value = e.target.value;
    setSliderValue(value);
    setWinChance(100 - value);
    setRollOver((1 * value).toFixed(2));
    setWinnings((96 / winChance).toFixed(2));
    updateSliderBackground(value);
  };

  const updateSliderBackground = (value) => {
    const slider = document.getElementById('dice-slider');
    slider.style.background = `linear-gradient(to right, #ff0000 ${value}%, #00ff00 ${value}%)`;
  };

  const handlePlay = async () => {
    setLoading(true);  // Start loading
    try {
      const response = await axios.post(
        'http://localhost:8080/api/dice/play/dice',
        {
          betAmount: amount,
          selectedNumber: sliderValue,
          winChance,
        },
        { withCredentials: true }  // Ensures cookies (JWT token) are sent with the request
      );

      const { win, winAmount, newBalance } = response.data;

      // Update the UI with the result
      if (win) {
        alert(`You won ${winAmount}! Your new balance is ${newBalance}.`);
      } else {
        alert(`You lost the bet. Your new balance is ${newBalance}.`);
      }

      setBalance(newBalance);  // Update the balance state
    } catch (error) {
      console.error('Error placing bet:', error);
      alert(`Error placing bet: ${error.response ? error.response.data.error : error.message}`);
    } finally {
      setLoading(false);  // End loading
    }
  };

  return (
    <div className="main">
      <div className="game-container">
        <div className="side-panel dice-side-panel">
          <h2 className="game-head">Controls</h2>
          <div className="side-inputs">
            <label>Amount:</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              // className="side-panel-inputs"
            />
          </div>
          <div className="side-inputs">
            <label>Net Gain on Win:</label>
            <input
              type="number"
              value={netGain}
              onChange={(e) => setNetGain(e.target.value)}
            />
          </div>
          <button id="play-button" onClick={handlePlay} disabled={loading}>
            {loading ? 'Playing...' : 'Play'}
          </button>
        </div>

        <div className="game-panel">
          <div className="slider-controls">
            <div className="slider-labels">
              <span>0</span>
              <span>25</span>
              <span>50</span>
              <span>75</span>
              <span>100</span>
            </div>
            <div className="slider-container">
              <input
                id="dice-slider"
                type="range"
                min="0"
                max="100"
                value={sliderValue}
                onChange={handleSliderChange}
              />
            </div>

            <div className="winning-odds">
              <div className="wo-item">
                <label>Multiplier:</label>
                <input className="wo-item-input" type="number" value={winnings} readOnly />
              </div>
              <div className="wo-item">
                <label>Roll Over:</label>
                <input className="wo-item-input" type="number" value={rollOver} readOnly />
              </div>
              <div className="wo-item">
                <label>Win Chance:</label>
                <input className="wo-item-input" type="number" value={winChance} readOnly />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Display the user's current balance */}
      {balance !== null && (
        <div className="balance-info">
          <h3>Your Balance: ${balance}</h3>
        </div>
      )}

      <div className="game-description-card">
        <img src={DiceImg} alt="Dice Game" className="game-description-image" />
        <div className="game-description-content">
          <div className="game-description-header">
            <h2>Play Dice Online</h2>
            <button className="description-button">Description</button>
          </div>
          <p>Roulo 50/50 Dice is a game mode in which players make predictions about the outcome of a virtual dice roll. A slider bar allows you to select whether you believe the resulting number will be above or below a certain value between 0 and 100.</p>
        </div>
      </div>
    </div>
  );
};

export default DiceGame;