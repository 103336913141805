import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameNine from '../img/SlotCards/pragmatic_vswayslions.png';
import '../GameElements.css';

function SlotGameNineCard(){
    return(
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameNine} alt="Slot Game Nine" />
            </div>
        </Link>
    );
}

export default SlotGameNineCard