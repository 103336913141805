import React, { useState, Fragment } from 'react';
import './Blackjack.css';
import axios from 'axios';
import BlackjackImg from '../components/img/blackjack.png';

function importAll(r) {
  let images = {};
  r.keys().map(item => { images[item.replace('./', '')] = r(item); });
  return images;
};

const images = importAll(require.context('./cards', false, /\.png/));

const getCardValue = (value) => {
  if (['jack', 'queen', 'king'].includes(value)) return 10;
  if (value === 'ace') return 11;
  return parseInt(value);
};

const calculateHandValue = (hand) => {
  let value = hand.reduce((sum, card) => sum + getCardValue(card.value), 0);
  let numAces = hand.filter(card => card.value === 'ace').length;
  while (value > 21 && numAces > 0) {
    value -= 10;
    numAces -= 1;
  }
  return value;
};

const Blackjack = () => {
  const [deck, setDeck] = useState([]);
  const [playerHand, setPlayerHand] = useState([]);
  const [dealerHand, setDealerHand] = useState([]);
  const [dealerHidden, setDealerHidden] = useState(true);
  const [splitHands, setSplitHands] = useState([[], []]);
  const [isSplit, setIsSplit] = useState(false);
  const [currentHandIndex, setCurrentHandIndex] = useState(0);
  const [bet, setBet] = useState('');
  const [playerMoney, setPlayerMoney] = useState(100);
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState('');
  const [gameStarted, setGameStarted] = useState(false);

  const startGame = async () => {
    try {
      const response = await axios.post('http://localhost:8080/api/blackjack/play/blackjack', { betAmount: bet }, { withCredentials: true });
      const { playerHand: initialPlayerHand, dealerHand: initialDealerHand, playerMoney } = response.data;

      setGameStarted(true);
      setPlayerHand([]);
      setDealerHand([]);

      setTimeout(() => setPlayerHand([initialPlayerHand[0]]), 500);
      setTimeout(() => setDealerHand([initialDealerHand[0]]), 1000);
      setTimeout(() => setPlayerHand(prevHand => [...prevHand, initialPlayerHand[1]]), 1500);
      setTimeout(() => setDealerHand(prevHand => [...prevHand, initialDealerHand[1]]), 2000);

      setPlayerMoney(playerMoney);
      setDealerHidden(true);
      setGameOver(false);
      setMessage('');
    } catch (error) {
      console.error('Error starting Blackjack game:', error);
    }
  };

  const hit = async () => {
    if (gameOver) return;
    try {
      const response = await axios.post('http://localhost:8080/api/blackjack/hit', { betAmount: bet }, { withCredentials: true });
      const { playerHand: updatedPlayerHand, outcome, playerMoney } = response.data;

      setPlayerHand(updatedPlayerHand);
      setPlayerMoney(playerMoney);

      if (outcome === 'lose') {
        setMessage('Player Busts! Dealer Wins.');
        setGameOver(true);
      } else if (outcome === 'win') {
        setMessage('Player Wins!');
        setGameOver(true);
      }
    } catch (error) {
      console.error('Error hitting:', error);
    }
  };

  const stand = async () => {
    if (gameOver) return;
    try {
      const response = await axios.post('http://localhost:8080/api/blackjack/stand', { betAmount: bet }, { withCredentials: true });
      const { dealerHand: updatedDealerHand, outcome, playerMoney } = response.data;

      setDealerHand(updatedDealerHand);
      setDealerHidden(false);  // Reveal the hidden dealer card

      let message = '';
      if (outcome === 'win') {
        message = 'Player Wins!';
      } else if (outcome === 'lose') {
        message = 'Dealer Wins!';
      } else {
        message = 'Push.';
      }

      setGameOver(true);
      setMessage(message);
      setPlayerMoney(playerMoney);  // Ensure the balance is updated in the frontend
    } catch (error) {
      console.error('Error standing:', error);
    }
  };

  const split = () => {
    if (playerHand.length === 2 && playerHand[0].value === playerHand[1].value) {
      setIsSplit(true);
      setSplitHands([[playerHand[0]], [playerHand[1]]]);
      const newDeck = [...deck];
      setSplitHands([[[...splitHands[0]], newDeck.pop()], [[...splitHands[1]], newDeck.pop()]]);
      setDeck(newDeck);
      setCurrentHandIndex(0);
    }
  };

  const doubleDown = () => {
    if (gameOver || playerHand.length !== 2) return;
    const newDeck = [...deck];
    const newPlayerHand = [...playerHand, newDeck.pop()];
    setDeck(newDeck);
    setPlayerHand(newPlayerHand);
    stand();
  };

  const getCardImage = (card, hidden = false) => {
    if (hidden) {
      return process.env.PUBLIC_URL + './cards/back.png';
    }
    return process.env.PUBLIC_URL + `./cards/${card.value}_of_${card.suit}.png`;
  };

  const resetGame = () => {
    startGame();
  };

  return (
    <div className="main">
      <div className="game-container">
        <div className="side-panel">
          <h2 className="game-head">Controls</h2>
          <div className="side-inputs" id="blackjack-amount">
            <label>Amount:</label>
            <input
              type="number"
              value={bet}
              onChange={(e) => setBet(parseFloat(e.target.value))}
              style={{ width: '112%' }}
            />
          </div>
          <div className="side-inputs">
            <div className="auto-clear-btns" style={{ width: '113.5%' }}>
              <button className="auto-pick" onClick={hit}>Hit</button>
              <button className="clear-table" onClick={stand}>Stand</button>
            </div>
            <div className="auto-clear-btns" style={{ width: '113.5%' }}>
              <button className="auto-pick" onClick={split} disabled={!gameStarted || gameOver || isSplit || playerHand.length !== 2 || playerHand[0].value !== playerHand[1].value}>Split</button>
              <button className="clear-table" onClick={doubleDown} disabled={!gameStarted || gameOver || isSplit}>Double</button>
            </div>
            <div className="auto-clear-btns">
              <button id="play-button" className="blackjack-play-button" style={{ width: '97.5%', marginTop: 10 }} onClick={resetGame}>Play</button>
            </div>
          </div>
        </div>

        <div className="game-panel blackjack">
          <div className="blackjack-container">
            <div className="hand">
              <h2 className="blackjack-head">Dealer's Hand</h2>
              <div className="blackjack-cards">
                {dealerHand.map((card, index) => (
                  <div key={index} className="blackjack-card">
                    <img src={getCardImage(card, dealerHidden && index === 1)} alt={`${card.value} of ${card.suit}`} />
                  </div>
                ))}
              </div>
              <div>Value: {dealerHidden ? getCardValue(dealerHand[0]?.value || 0) : calculateHandValue(dealerHand)}</div>
            </div>

            <div className="hand">
              <h2 className="blackjack-head">Player's Hand</h2>
              <div className="blackjack-cards">
                {playerHand.map((card, index) => (
                  <span className="blackjack-card" key={index}><img src={getCardImage(card)} alt={`${card.value} of ${card.suit}`} /></span>
                ))}
              </div>
              <div>Value: {calculateHandValue(playerHand)}</div>
            </div>
            {gameOver && <div className="blackjack-message">{message}</div>}
          </div>
        </div>
      </div>

      <div className="game-description-card">
        <img src={BlackjackImg} alt="Blackjack Game" className="game-description-image" />
        <div className="game-description-content">
          <div className="game-description-header">
            <h2>Play Blackjack Online</h2>
            <button className="description-button">Description</button>
          </div>
          <p>Roulo Blackjack is a game mode in which players __.</p>
        </div>
      </div>
    </div>
  );
};

export default Blackjack;