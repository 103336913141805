import React from 'react';

function SecuritySettings() {
  return (
    <div className="settings-section">
      <h2>Security</h2>
      <div className="settings-field">
        <label className="security-settings-label">Change Password</label>
        <input className="security-settings-input" type="password" placeholder="New Password" />
        <input className="security-settings-input" type="password" placeholder="Confirm New Password" />
        <button className="security-btn">Change Password</button>
      </div>

      {/* <div className="settings-field">
        <label>Two-Factor Authentication</label>
        <p>Enabling two-factor authentication will require a code when updating or viewing sensitive information. Need Help? <a href="#">Read our 2FA Guide</a></p>
        <button className="security-btn">Enable 2FA</button>
      </div> */}

      {/* <div className="settings-field">
        <label>Multi-Device Logout</label>
        <p>If you think your account may have been compromised, reset your password and then hit the button below to end all current active sessions, including this one.</p>
        <div className="multi-device-buttons">
          <button className="security-btn">View Recent Sessions</button>
          <button className="security-btn logout-btn">Logout Everywhere</button>
        </div>
      </div> */}
    </div>
  );
}

export default SecuritySettings;
