import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import PlinkoCard from '../components/plinkoCard';
import GameElements from '../components/GameElements';
import DiceCard from '../components/DiceCard';
import MinesCard from '../components/MinesCard';
import BlackjackCard from '../components/BlackJackCard';
import CrashCard from '../components/CrashCard';
import KenoCard from '../components/KenoCard';
import RouletteCard from '../components/RouletteCard';
// import TowersCard from '../components/TowersCard';
import BaccaratCard from '../components/BaccaratCard';
// import WheelCard from '../components/WheelCard';
import SlotOneCard from '../components/SlotCards/SlotOneCard';
import SlotTwoCard from '../components/SlotCards/SlotTwoCard';
import SlotThreeCard from '../components/SlotCards/SlotThreeCard';
import SlotFourCard from '../components/SlotCards/SlotFourCard';
import SlotFiveCard from '../components/SlotCards/SlotFiveCard';
import SlotSixCard from '../components/SlotCards/SlotSixCard';
import SlotSevenCard from '../components/SlotCards/SlotSevenCard';
import SlotEightCard from '../components/SlotCards/SlotEightCard';
import SlotNineCard from '../components/SlotCards/SlotNineCard';
import SlotTenCard from '../components/SlotCards/SlotTenCard';
import RouloChips from './imgs/roulo_chips_blue.png';
// import TopCarouselCardOne from '../components/topCarouselCard1';
// import TopCarouselCardTwo from '../components/topCarouselCard2';
// import TopCarouselCardThree from '../components/topCarouselCard3';
// import TopCarouselCardFour from '../components/topCarouselCard4';
import FeatureCards from '../components/FeatureCards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import CategoryNav from '../components/CategoryNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faEgg, faDollarSign, faMoneyBillWave, faHatWizard } from '@fortawesome/free-solid-svg-icons';

function Home() {
  const [slidesPerViewBottom, setslidesPerViewBottom] = useState(7);
  const [slidesPerViewTop, setSlidesPerViewTop] = useState(3);
  const [newSpaceBetweenTop, setSpaceBetweenTop] = useState(40);
  // const [newSpaceBetweenBottom, setSpaceBetweenBottom] = useState(-40);

  const location = useLocation();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [username, setUsername] = useState('');
  const [category, setCategory] = useState('lobby');
  const [isRegisterModelOpen, setIsRegisterModelOpen] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const regModel = queryParams.get('regModel');
    const tab = queryParams.get('tab');

    if (regModel === 'register' && tab === 'register') {
      setIsRegisterModelOpen(true);
    }
  }, [location]);

  const openRegisterModel = () => navigate('/?regModel=register&tab=register');

  useEffect(() => {
    const handleResize = () => {
      const newslidesPerViewBottom = window.innerWidth <= 768 ? 4 : 7;
      setslidesPerViewBottom(newslidesPerViewBottom);

      const newSlidesPerViewTop = window.innerWidth <= 768 ? 2 : 3;
      setSlidesPerViewTop(newSlidesPerViewTop);

      const updatedSpaceBetweenTop = window.innerWidth <= 768 ? 10 : 18;
      setSpaceBetweenTop(updatedSpaceBetweenTop);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.state?.user) {
      setUsername(location.state.user.username);
      setShowPopup(true);
    }
  }, [location.state]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const originalsCarouselRef = useRef(null);
  const slotsCarouselRef = useRef(null);
  const recommendedCarouselRef = useRef(null);

  const scrollLeft = (carouselRef) => {
    carouselRef.current.scrollBy({ left: -160, behavior: 'smooth' });
  };

  const scrollRight = (carouselRef) => {
    carouselRef.current.scrollBy({ left: 160, behavior: 'smooth' });
  };

  // Function to render the content based on the selected category
  const renderContent = () => {
    switch (category) {
      case 'lobby':
        return (
          <div className="lobby-container">
            <h1 className="game-slider-header" style={{paddingTop: "20px"}}>
              <FontAwesomeIcon icon={faEgg} className="game-icons slider-head-icon" />
              Roulo Originals
            </h1>
            <div className="sub-main">
              {/* <Swiper
                modules={[Navigation]}
                slidesPerView={slidesPerViewBottom}
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide>
                  <GameElements />
                </SwiperSlide>
                <SwiperSlide>
                  <DiceCard />
                </SwiperSlide>
                <SwiperSlide>
                  <MinesCard />
                </SwiperSlide>
                <SwiperSlide>
                  <BlackjackCard />
                </SwiperSlide>
                <SwiperSlide>
                  <CrashCard />
                </SwiperSlide>
                <SwiperSlide>
                  <KenoCard />
                </SwiperSlide>
                <SwiperSlide>
                  <BaccaratCard />
                </SwiperSlide>
                <SwiperSlide>
                  <RouletteCard />
                </SwiperSlide>
              </Swiper> */}
              <div className="roulo-originals-container">
                <div className="carousel">
                  <button className="carousel-arrow left" onClick={() => scrollLeft(originalsCarouselRef)}>&lt;</button>
                  <div className="carousel-track" ref={originalsCarouselRef}>
                    <GameElements />
                    <DiceCard />
                    <MinesCard />
                    <BlackjackCard />
                    <CrashCard />
                    <KenoCard />
                    <BaccaratCard />
                  </div>
                  <button className="carousel-arrow right" onClick={() => scrollRight(originalsCarouselRef)}>&gt;</button>
                </div>
              </div>
            </div>

            <h1 className="game-slider-header">
              <FontAwesomeIcon icon={faDollarSign} className="game-icons slider-head-icon" />
              Slot Games
            </h1>
            <div className="sub-main">
              {/* <Swiper
                modules={[Navigation, Pagination]}
                // spaceBetween={newSpaceBetweenBottom}
                slidesPerView={slidesPerViewBottom}
                // navigation
                // pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide>
                  <SlotOneCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotTwoCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotThreeCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotFourCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotFiveCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotSixCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotSevenCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotEightCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotNineCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotTenCard />
                </SwiperSlide>
              </Swiper> */}
              <div className="roulo-originals-container">
                <div className="carousel">
                  <button className="carousel-arrow left" onClick={() => scrollLeft(slotsCarouselRef)}>&lt;</button>
                  <div className="carousel-track" ref={slotsCarouselRef}>
                    <SlotOneCard />
                    <SlotTwoCard />
                    <SlotThreeCard />
                    <SlotFourCard />
                    <SlotFiveCard />
                    <SlotSixCard />
                    <SlotSevenCard />
                    <SlotEightCard />
                    <SlotNineCard />
                    <SlotTenCard />
                  </div>
                  <button className="carousel-arrow right" onClick={() => scrollRight(slotsCarouselRef)}>&gt;</button>
                </div>
              </div>
            </div>

            <h1 className="game-slider-header">
              <FontAwesomeIcon icon={faMoneyBillWave} className="game-icons slider-head-icon" />
              Recommended
            </h1>
            <div className="sub-main">
              {/* <Swiper
                modules={[Navigation]}
                // spaceBetween={newSpaceBetweenBottom} // Change to newSpaceBetweenBottom later
                slidesPerView={slidesPerViewBottom}
              // navigation
              >
                <SwiperSlide>
                  <GameElements />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotSevenCard />
                </SwiperSlide>
                <SwiperSlide>
                  <DiceCard />
                </SwiperSlide>
                <SwiperSlide>
                  <CrashCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotNineCard />
                </SwiperSlide>
                <SwiperSlide>
                  <BlackjackCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotEightCard />
                </SwiperSlide>
                <SwiperSlide>
                  <KenoCard />
                </SwiperSlide>
                <SwiperSlide>
                  <BaccaratCard />
                </SwiperSlide>
                <SwiperSlide>
                  <RouletteCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotFiveCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotThreeCard />
                </SwiperSlide>
                <SwiperSlide>
                  <SlotSixCard />
                </SwiperSlide>
              </Swiper> */}
              <div className="roulo-originals-container">
                <div className="carousel">
                  <button className="carousel-arrow left" onClick={() => scrollLeft(recommendedCarouselRef)}>&lt;</button>
                  <div className="carousel-track" ref={recommendedCarouselRef}>
                    <MinesCard />
                    <GameElements />
                    <SlotSevenCard />
                    <DiceCard />
                    <CrashCard />
                    <SlotNineCard />
                    <BlackjackCard />
                    <SlotEightCard />
                    <KenoCard />
                    <BaccaratCard />
                    <SlotFiveCard />
                    <SlotThreeCard />
                    <SlotSixCard />
                  </div>
                  <button className="carousel-arrow right" onClick={() => scrollRight(recommendedCarouselRef)}>&gt;</button>
                </div>
              </div>
            </div>
          </div>
        );
      case 'originals':
        return (
          <div>
            <h1 className="game-slider-header">
              <FontAwesomeIcon icon={faEgg} className="game-icons slider-head-icon" />
              Roulo Originals
            </h1>
            <div className="sub-main-solos">
              <GameElements />
              <DiceCard />
              <MinesCard />
              <BlackjackCard />
              <CrashCard />
              <KenoCard />
              <BaccaratCard />
              {/* <TowersCard /> */}
              {/* <RouletteCard /> */}
              {/* <WheelCard /> */}
            </div>
          </div>
        );
      case 'slots':
        return (
          <div>
            <h1 className="game-slider-header">
              <FontAwesomeIcon icon={faDollarSign} className="game-icons slider-head-icon" />
              Slot Games
            </h1>
            <div className="sub-main-solos">
              <SlotOneCard />
              <SlotTwoCard />
              <SlotThreeCard />
              <SlotFourCard />
              <SlotFiveCard />
              <SlotSixCard />
              <SlotSevenCard />
              <SlotEightCard />
              <SlotNineCard />
              <SlotTenCard />
            </div>
          </div>
        );
      case 'table':
        return (
          <div>
            <h1 className="game-slider-header">
              <FontAwesomeIcon icon={faMoneyBillWave} className="game-icons slider-head-icon" />
              Recommended
            </h1>
            <div className="sub-main-solos">
              <MinesCard />
              {/* <WheelCard />
              <TowersCard /> */}
              <GameElements />
              <SlotSevenCard />
              <DiceCard />
              <CrashCard />
              <SlotNineCard />
              <BlackjackCard />
              <SlotEightCard />
              <KenoCard />
              <BaccaratCard />
              <RouletteCard />
              <SlotFiveCard />
              <SlotThreeCard />
              <SlotSixCard />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="main">
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Welcome, {username}!</h2>
            <p>Enjoy the website!</p>
            <button onClick={handleClosePopup}>OK</button>
          </div>
        </div>
      )}
      <div className="swiper-container">
        <div className="hero-banner">
          <div className="hero-text">
            <h1>Welcome to the Best Crypto Casino</h1>
            <p>Luck is just a spin away. Sign up now for free spins and bonuses.</p>
            <a onClick={openRegisterModel} className="btn-reg">Register</a>
          </div>
          {/* <div className="hero-image-container"> */}
          <img src={RouloChips} alt="Roulo Casino Img" className="hero-image" />
          {/* </div> */}
        </div>

        <FeatureCards />

        {/* <CategoryNav setCategory={setCategory} /> */}
        {renderContent()}
      </div>

      <div className="livefeed">
        <h1 className="game-slider-header" style={{marginBottom: "20px"}}>
          <FontAwesomeIcon icon={faHatWizard} className="game-icons slider-head-icon" />
          Recent Games
        </h1>
        <div class="livefeed-row top-livefeed-row">
          <div class="livefeed-row-one">Game</div>
          <div class="livefeed-row-one">User</div>
          <div class="livefeed-row-one">Bet Amount</div>
          <div class="livefeed-row-one livefeed-multiplier">Multiplier</div>
          <div class="livefeed-row-one livefeed-payout">Payout</div>
        </div>
        <div class="livefeed-row">
          <div class="livefeed-game-name">Roulette</div>
          <div class="livefeed-username">Hidden</div>
          <div class="livefeed-bet-amount">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.18
          </div>
          <div class="livefeed-multiplier">0.00x</div>
          <div class="livefeed-payout">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.00
          </div>
        </div>
        <div class="livefeed-row">
          <div class="livefeed-game-name">Baccarat</div>
          <div class="livefeed-username">Hidden</div>
          <div class="livefeed-bet-amount">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.18
          </div>
          <div class="livefeed-multiplier">0.00x</div>
          <div class="livefeed-payout">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.00
          </div>
        </div>
        <div class="livefeed-row">
          <div class="livefeed-game-name">Blackjack</div>
          <div class="livefeed-username">Hidden</div>
          <div class="livefeed-bet-amount">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.18
          </div>
          <div class="livefeed-multiplier">0.00x</div>
          <div class="livefeed-payout">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.00
          </div>
        </div>
        <div class="livefeed-row">
          <div class="livefeed-game-name">Crash</div>
          <div class="livefeed-username">Hidden</div>
          <div class="livefeed-bet-amount">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.18
          </div>
          <div class="livefeed-multiplier">0.00x</div>
          <div class="livefeed-payout">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.00
          </div>
        </div>
        <div class="livefeed-row">
          <div class="livefeed-game-name">50/50 Dice</div>
          <div class="livefeed-username">Hidden</div>
          <div class="livefeed-bet-amount">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.18
          </div>
          <div class="livefeed-multiplier">0.00x</div>
          <div class="livefeed-payout">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.00
          </div>
        </div>
        <div class="livefeed-row">
          <div class="livefeed-game-name">Mines</div>
          <div class="livefeed-username">Hidden</div>
          <div class="livefeed-bet-amount">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.18
          </div>
          <div class="livefeed-multiplier">0.00x</div>
          <div class="livefeed-payout">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            0.00
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;