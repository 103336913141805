import React from 'react';
import { Link } from 'react-router-dom';
import Keno from './img/keno.png';
import './GameElements.css';

function KenoCard(){
    return(
        <Link to="/keno" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={Keno} alt="Keno Game" />
            </div>
        </Link>
    );
}

export default KenoCard