import React from 'react';
import { Link } from 'react-router-dom';
import Roulette from './img/roulette.png';
import './GameElements.css';

function RouletteCard(){
    return(
        <Link to="/roulette" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={Roulette} alt="Roulette Game" />
            </div>
        </Link>
    );
}

export default RouletteCard