import React from 'react';

function VerificationSettings() {
  return (
    <div className="settings-section verification-section">
      <h2>Verification</h2>
      <div className="verification-tabs">
        <div className="verification-tab active">
          <h3>Your Details</h3>
          <p>Verification Level 1</p>
        </div>
        <div className="verification-tab">
          <h3>Identity Verification</h3>
          <p>Verification Level 2</p>
        </div>
        <div className="verification-tab">
          <h3>Address Verification</h3>
          <p>Verification Level 3</p>
        </div>
        <div className="verification-tab">
          <h3>Source of Funds</h3>
          <p>Verification Level 4</p>
        </div>
      </div>
      <div className="verification-details">
        <p>Please fill out all fields below.</p>
        <form className="verification-form">
          <div className="verification-form-row">
            <div className="verification-form-field">
              <label>First Name</label>
              <input type="text" placeholder="First Name" />
            </div>
            <div className="verification-form-field">
              <label>Last Name</label>
              <input type="text" placeholder="Last Name" />
            </div>
          </div>
          <div className="verification-form-row">
            <div className="verification-form-field">
              <label>Date of Birth</label>
              <input type="date" placeholder="Date of Birth" />
            </div>
            <div className="verification-form-field">
              <label>Country</label>
              <select>
                <option>Country</option>
                {/* Add more options as needed */}
              </select>
            </div>
          </div>
          <div className="verification-form-row">
            <div className="verification-form-field">
              <label>Address Line 1</label>
              <input type="text" placeholder="Address Line 1" />
            </div>
            <div className="verification-form-field">
              <label>Address Line 2</label>
              <input type="text" placeholder="Address Line 2" />
            </div>
          </div>
          <div className="verification-form-row">
            <div className="verification-form-field">
              <label>City</label>
              <input type="text" placeholder="City" />
            </div>
            <div className="verification-form-field">
              <label>Province/State</label>
              <input type="text" placeholder="Province/State" />
            </div>
          </div>
          <div className="verification-form-row">
            <div className="verification-form-field">
              <label>Postal Code</label>
              <input type="text" placeholder="Postal Code" />
            </div>
            <div className="verification-form-field">
              <label>Phone Number</label>
              <input type="text" placeholder="Phone Number" />
            </div>
          </div>
          <button type="submit" className="save-btn">Save</button>
        </form>
      </div>
    </div>
  );
}

export default VerificationSettings;
