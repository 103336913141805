import React, { useState } from 'react';
import './SidebarNav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faGamepad, faStar, faCircleDollarToSlot, faSpinner, faCircleDot, faMeteor, faDice, faBomb, faDiamond, faSquareXmark, faMasksTheater, faCrown } from '@fortawesome/free-solid-svg-icons';

const SidebarNav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [openTab, setOpenTab] = useState(null);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const toggleTab = (tab) => {
        setOpenTab(openTab === tab ? null : tab);
    };

    return (
        <div className={`sidenav-main-container ${isOpen ? 'sidebar-open' : ''}`}>
            <nav className={`half-shadow sidebar ${isOpen ? 'open' : ''}`}>
                <div id="opacity-container">
                    <div className="sidebar-header">
                        <button id="sidebar-btn" onClick={toggleSidebar} className={isOpen ? 'rotate' : ''}>
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                    </div>
                    <ul className="sidebar-list">
                        <ul className="sidebar-items">
                            <li className="sidebar-item top">
                                <a href="./" className="sidebar-link">
                                    <FontAwesomeIcon icon={faHome} title="Home" />
                                    <span>Home</span>
                                </a>
                            </li>
                            <li className={`sidebar-item games ${openTab === 'games' ? 'active' : ''}`} onClick={() => isOpen && toggleTab('games')}>                                <div className="sidebar-link">
                                <a href="./originals" className="sidebar-link">
                                    <FontAwesomeIcon icon={faGamepad} title="Games" />
                                </a>
                                <span>Originals</span>
                                {/* {isOpen ? <span>Games</span> : <span className="tooltip">Games</span>} */}
                            </div>
                                {isOpen && openTab === 'games' && (
                                    <ul className="submenu">
                                        <li><a href="./plinko"><FontAwesomeIcon icon={faCircleDot} className="game-icons" /> Plinko</a></li>
                                        <li><a href="./dice"><FontAwesomeIcon icon={faDice} className="game-icons" /> Dice</a></li>
                                        <li><a href="./mines"><FontAwesomeIcon icon={faBomb} className="game-icons" /> Mines</a></li>
                                        <li><a href="./blackjack"><FontAwesomeIcon icon={faDiamond} className="game-icons" /> Blackjack</a></li>
                                        <li><a href="./crash"><FontAwesomeIcon icon={faMeteor} className="game-icons" /> Crash</a></li>
                                        <li><a href="./keno"><FontAwesomeIcon icon={faSquareXmark} className="game-icons" /> Keno</a></li>
                                        <li><a href="./baccarat"><FontAwesomeIcon icon={faMasksTheater} className="game-icons" /> Baccarat</a></li>
                                        {/* <li><a href="./tower"><FontAwesomeIcon icon={faGopuram} className="game-icons" /> Tower</a></li> */}
                                        {/* <li><a href="./roulette"><FontAwesomeIcon icon={faCircleNotch} className="game-icons" /> Roulette</a></li> */}
                                        {/* <li><a href="./slots"><FontAwesomeIcon icon={faCrown} className="game-icons" /> Slots</a></li> */}
                                    </ul>
                                )}
                            </li>
                            {/* <li className={`sidebar-item originals ${openTab === 'originals' ? 'active' : ''}`} onClick={() => isOpen && toggleTab('originals')}>                                <div className="sidebar-link">
                                <a href="./originals" className="sidebar-link">
                                    <FontAwesomeIcon icon={faStar} title="Originals" />
                                </a>
                                <span>Originals</span>
                            </div>
                                {isOpen && openTab === 'originals' && (
                                    <ul className="submenu">
                                        <li><a href="./games-1">OG 1</a></li>
                                        <li><a href="./games-2">OG 2</a></li>
                                    </ul>
                                )}
                            </li> */}
                            <li className={`sidebar-item bottom ${openTab === 'slots' ? 'active' : ''}`} onClick={() => isOpen && toggleTab('slots')}>                                <div className="sidebar-link">
                                <a href="./slots" className="sidebar-link">
                                    <FontAwesomeIcon icon={faCircleDollarToSlot} title="Slots" />
                                </a>
                                <span>Slots</span>
                            </div>
                                {isOpen && openTab === 'slots' && (
                                    <ul className="submenu">
                                        <li><a href="./slot-1">Slot 1</a></li>
                                        <li><a href="./slot-2">Slot 2</a></li>
                                        <li><a href="./slot-3">Slot 3</a></li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default SidebarNav;