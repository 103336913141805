import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { AuthContext } from '../context/AuthContext';
import RouloLogo from './img/roulo_logo_2.png';
import DepositModal from './DepositModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faGear, faUser } from '@fortawesome/free-solid-svg-icons';
import ProfileModel from './ProfileModel';
import RegisterModel from './Register';
import SignInModel from './SignIn';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { username, balance, loading } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false); 
  const [isModelOpen, setIsModelOpen] = useState(false); 
  const [isRegisterModelOpen, setIsRegisterModelOpen] = useState(false); 
  const [isSignInModelOpen, setIsSignInModelOpen] = useState(false); 

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const modal = queryParams.get('modal');
    const model = queryParams.get('model');
    const regModel = queryParams.get('regModel');
    const signInModel = queryParams.get('signInModel');
    const tab = queryParams.get('tab');

    if (modal === 'cashier' && tab === 'deposit') {
      setIsModalOpen(true);
    }
    if (model === 'profile' && tab ==='profile') {
      setIsModelOpen(true);
    }
    if (regModel === 'register' && tab ==='register') {
      setIsRegisterModelOpen(true);
    }
    if (signInModel === 'signin' && tab ==='signin') {
      setIsSignInModelOpen(true);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsOpen(!isOpen); 
  };

  const openModal = () => navigate('/?modal=cashier&tab=deposit');
  const closeModal = () => {
    setIsModalOpen(false);
    navigate('/');
  };

  const openModel = () => navigate('/?model=profile&tab=profile');
  const closeModel = () => {
    setIsModelOpen(false);
    navigate('/');
  };

  const openRegisterModel = () => navigate('/?regModel=register&tab=register');
  const closeRegisterModel = () => {
    setIsRegisterModelOpen(false);
    navigate('/');
  }

  const openSignInModel = () => navigate('/?signInModel=signin&tab=signin');
  const closeSignInModel = () => {
    setIsSignInModelOpen(false);
    navigate('/');
  }

  const handleOutsideClick = (e) => {
    if (isOpen && !e.target.closest(".navbar-username")) {
      setIsOpen(false);  
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  const handleLogout = async () => {
    try {
      const response = await fetch('http://localhost:8080/api/users/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' // This ensures cookies are sent and received
      });

      if (response.ok) {
        // Handle frontend state or redirection after logout
        window.location.href = '/';
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <>
      <header className="header">
        <a href="/" className="logo">
          <img src={RouloLogo} className="roulo-logo" alt="Roulo" />
        </a>
        <button className="menu-toggle" onClick={toggleMenu}>
          <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
        </button>
        <nav className="rightNav">
          {loading ? (
            <span className="navbar-username">Loading...</span>
          ) : (
            username ? (
              <>
                <div className="middleNav deposit-container">
                  <span className="user-balance">${balance.toFixed(2)}</span>
                  <button className="deposit" onClick={openModal}>
                    Deposit
                  </button>
                </div>
                <div className="btn-reg btn-username" onClick={toggleMenu}>
                  <span className="navbar-username">
                    {username} <i className={`fas fa-caret-down ${isOpen ? 'open' : ''}`}></i>
                  </span>
                  {isOpen && (
                    <div className="user-dropdown-menu">
                      <a onClick={openModel} className="user-dropdown-item">
                      {/* <button className="user-dropdown-item" onClick={openModel}> */}
                        <FontAwesomeIcon icon={faUser} className="user-icon" />
                        Profile
                      {/* </button> */}
                      </a>
                      <Link to="/settings" className="user-dropdown-item">
                        <FontAwesomeIcon icon={faGear} className="user-icon" />
                        Settings
                      </Link>
                      <a onClick={handleLogout} className="user-dropdown-item">
                        <FontAwesomeIcon icon={faPowerOff} className="user-icon" />
                        Logout
                      </a>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {/* <Link to="/signin" className="btn-in" onClick={openRegisterModel}>Sign In</Link> */}
                <a onClick={openSignInModel} className="btn-in">Sign In</a>
                {/* <Link to="/register" className="btn-reg">Register</Link> */}
                <a onClick={openRegisterModel} className="btn-reg">Register</a>
              </>
            )
          )}
        </nav>
      </header>
      <DepositModal isOpen={isModalOpen} closeModal={closeModal} />
      <ProfileModel isOpen={isModelOpen} closeModal={closeModel} />
      <SignInModel isOpen={isSignInModelOpen} closeModal={closeSignInModel} />
      <RegisterModel isOpen={isRegisterModelOpen} closeModal={closeRegisterModel} />
    </>
  );
};

export default Navbar;