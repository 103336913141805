import React, { useState } from 'react';
import axios from 'axios';
import './Baccarat.css';
import BaccaratImg from '../components/img/baccarat.png';

const Baccarat = () => {
    const [playerHand, setPlayerHand] = useState([]);
    const [bankerHand, setBankerHand] = useState([]);
    const [message, setMessage] = useState('');
    const [bet, setBet] = useState('');
    const [playerValue, setPlayerValue] = useState(0);
    const [bankerValue, setBankerValue] = useState(0);
    const [isDealing, setIsDealing] = useState(false);
    const [betLocked, setBetLocked] = useState(false);
    const [flyOut, setFlyOut] = useState(false);
    const [amount, setAmount] = useState('');

    const dealBaccarat = async () => {
        if (!bet || !amount) {
            setMessage("Please place a bet and enter an amount before dealing.");
            return;
        }

        try {
            setFlyOut(true); // Start the fly-out animation
            setIsDealing(true);
            setBetLocked(true);

            // Make the API call to the backend to deal cards and get the result
            const response = await axios.post('http://localhost:8080/api/baccarat/deal', {
                betAmount: amount,
                betType: bet,
            }, { withCredentials: true });

            const { playerHand, bankerHand, playerValue, bankerValue, outcome } = response.data;

            setPlayerHand(playerHand.map(card => `${card.value} of ${card.suit}`));
            setBankerHand(bankerHand.map(card => `${card.value} of ${card.suit}`));
            setPlayerValue(playerValue);
            setBankerValue(bankerValue);

            setMessage(outcome === 'Player' ? 'Player wins!' : outcome === 'Banker' ? 'Banker wins!' : "It's a tie!");
        } catch (error) {
            setMessage(error.response ? error.response.data.error : error.message);
        } finally {
            setFlyOut(false); // Reset the fly-out animation
            setIsDealing(false);
        }
    };

    const handleNewGame = () => {
        setPlayerHand([]);
        setBankerHand([]);
        setMessage('');
        setBet('');
        setPlayerValue(0);
        setBankerValue(0);
        setIsDealing(false);
        setBetLocked(false);
        setAmount('');
    };

    const handlePlaceBet = (type) => {
        if (betLocked) return;
        setBet(type);
        setMessage('');
    };

    return (
        <div className="main">
            <div className="game-container">
                <div className="side-panel">
                    <h2 className="game-head">Controls</h2>
                    <div className="side-inputs" id="baccarat-amount">
                        <label>Amount:</label>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            style={{ width: '113.5%' }}
                        />
                    </div>
                    <div className="side-input">
                        <div className="auto-clear-btns" style={{ width: '113.5%' }}>
                            <button className="auto-pick" onClick={() => handlePlaceBet('Player')} disabled={betLocked}>Bet on Player</button>
                            <button className="clear-table" onClick={() => handlePlaceBet('Banker')} disabled={betLocked}>Bet on Banker</button>
                        </div>
                        <div className="auto-clear-btns" style={{ width: '113.5%' }}>
                            <button className="auto-pick" onClick={() => handlePlaceBet('Tie')} disabled={betLocked}>Bet on Tie</button>
                            <button className="clear-table" disabled={!bet || isDealing} onClick={dealBaccarat}>Deal Cards</button>
                        </div>
                        <div className="auto-clear-btns">
                            <button id="play-button" style={{ width: '100%', marginTop: 10 }} onClick={handleNewGame}>New Game</button>
                        </div>
                    </div>
                </div>

                <div className="game-panel baccarat">
                    <div className="baccarat-container">
                        <div className="hand">
                            <h2 className="baccarat-head">Player's Hand</h2>
                            <div className="baccarat-cards">
                                {playerHand.map((card, index) => (
                                    <div
                                        key={index}
                                        className={`baccarat-card ${index === 2 ? 'third-card' : ''} ${flyOut ? 'fly-out' : ''}`}
                                        style={{ '--delay': `${index * 0.5}s` }}
                                    >
                                        {card}
                                    </div>
                                ))}
                            </div>
                            <p>Player's Hand Value: {playerValue}</p>
                        </div>
                        <div className="hand">
                            <h2 className="baccarat-head">Banker's Hand</h2>
                            <div className="baccarat-cards">
                                {bankerHand.map((card, index) => (
                                    <div
                                        key={index}
                                        className={`baccarat-card ${index === 2 ? 'third-card' : ''} ${flyOut ? 'fly-out' : ''}`}
                                        style={{ '--delay': `${index * 0.5}s` }}
                                    >
                                        {card}
                                    </div>
                                ))}
                            </div>
                            <p>Banker's Hand Value: {bankerValue}</p>
                        </div>
                        {message && <p className="baccarat-message">{message}</p>}
                        {bet && <p className="baccarat-message">Bet placed on: {bet}</p>}
                    </div>
                </div>
            </div>

            <div className="game-description-card">
                <img src={BaccaratImg} alt="Baccarat Game" className="game-description-image" />
                <div className="game-description-content">
                    <div className="game-description-header">
                        <h2>Play Baccarat Online</h2>
                        <button className="description-button">Description</button>
                    </div>
                    <p>Roulo Baccarat is a game mode in which players __.</p>
                </div>
            </div>
        </div>
    );
};

export default Baccarat;