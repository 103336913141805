import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameSeven from '../img/SlotCards/pragmatic_vs20starlightx.png';
import '../GameElements.css';

function SlotGameSevenCard(){
    return(
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameSeven} alt="Slot Game Seven" />
            </div>
        </Link>
    );
}

export default SlotGameSevenCard