import React, { useState, useEffect } from 'react';
import GameElements from '../components/GameElements';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import './Slots.css';
import { FaSearch } from 'react-icons/fa';

function Tables() {
    const [SlidesPerViewBottom, setSlidesPerViewBottom] = useState(7);
    const [slidesPerViewTop, setSlidesPerViewTop] = useState(3);
    const [newSpaceBetweenTop, setSpaceBetweenTop] = useState(40);
    const [newSpaceBetweenBottom, setSpaceBetweenBottom] = useState(-40);

    useEffect(() => {
        const handleResize = () => {
            const newSlidesPerViewBottom = window.innerWidth <= 768 ? 4 : 7;
            setSlidesPerViewBottom(newSlidesPerViewBottom);

            const newSlidesPerViewTop = window.innerWidth <= 768 ? 2 : 3;
            setSlidesPerViewTop(newSlidesPerViewTop);

            const newSpaceBetweenTop = window.innerWidth <= 768 ? 10 : 18;
            setSpaceBetweenTop(newSpaceBetweenTop);

            const newSpaceBetweenBottom = window.innerWidth <= 768 ? -120 : -60;
            setSpaceBetweenBottom(newSpaceBetweenBottom);
        };

        // Listen for window resize events
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="slots-page">
            <div className="swiper-container-slots">
                <div className="header-slots">
                    <h1>Tables</h1>
                    <div className="search-container">
                        <FaSearch className="icon" />
                        <input type="text" className="search-bar" placeholder="Search..." />
                    </div>
                </div>
                <div className="sub-main">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={newSpaceBetweenBottom}
                        slidesPerView={SlidesPerViewBottom}
                        navigation
                    >
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                        <SwiperSlide>
                            <GameElements />
                        </SwiperSlide>
                    </Swiper>

                </div>


                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={newSpaceBetweenBottom}
                    slidesPerView={SlidesPerViewBottom}
                    navigation
                    pagination={{ clickable: true }}
                >
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                    <SwiperSlide>
                        <GameElements />
                    </SwiperSlide>
                </Swiper>

            </div>
        </div>
    );
}

export default Tables;