import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameFour from '../img/SlotCards/pragmatic_vs20fruitsw.png';
import '../GameElements.css';

function SlotGameFourCard(){
    return(
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameFour} alt="Slot Game Four" />
            </div>
        </Link>
    );
}

export default SlotGameFourCard