import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameTen from '../img/SlotCards/pragmatic_vswaysmadame.png';
import '../GameElements.css';

function SlotGameTenCard(){
    return(
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameTen} alt="Slot Game Ten" />
            </div>
        </Link>
    );
}

export default SlotGameTenCard