import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameTwo from '../img/SlotCards/pragmatic_vs15godsofwar.png';
import '../GameElements.css';

function SlotGameTwoCard(){
    return(
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameTwo} alt="Slot Game Two" />
            </div>
        </Link>
    );
}

export default SlotGameTwoCard